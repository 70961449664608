define("ccl-scoring-admin-ui/nav-offsidebar/service", ["exports", "ccl-scoring-admin-ui/utils/get-dom"], function (_exports, _getDom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    'isOpen': false,
    'showToggle': false,

    toggle() {
      return this.isOpen ? this.closeNav() : this.openNav();
    },

    closeNav() {
      Ember.set(this, 'isOpen', false);

      this._updateBodyClass('offsidebar-open');
    },

    openNav() {
      Ember.set(this, 'isOpen', true);

      this._updateBodyClass(null, 'offsidebar-open');
    },

    _updateBodyClass(nameToRemove, nameToSet) {
      let context = Ember.getOwner(this);
      let body = (0, _getDom.default)(context).body;
      let attr = body.getAttribute('class');
      let classList = attr ? attr.split(/\s+/) : [];
      let namesToSet = nameToSet ? nameToSet.split(/\s+/) : [];
      let namesToRemove = nameToRemove ? nameToRemove.split(/\s+/) : [];
      namesToRemove.forEach(name => {
        let index = classList.indexOf(name);

        if (index !== -1) {
          classList.splice(index, 1);
        }
      });
      namesToSet.forEach(name => {
        if (classList.indexOf(name) === -1) {
          classList.push(name);
        }
      });
      body.setAttribute('class', classList.join(' '));
    }

  });

  _exports.default = _default;
});