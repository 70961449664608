define("ccl-scoring-admin-ui/components/table-row-toggle/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dFn5jlxh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"bs-button\",null,[[\"type\",\"onClick\"],[[24,[\"primary\"]],[28,\"action\",[[23,0,[]],[28,\"toggle\",[\"expanded\",[24,[\"row\"]]],null]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"row\",\"expanded\"]]],null,{\"statements\":[[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"chevron-down\"],null],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"chevron-right\"],null],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/table-row-toggle/template.hbs"
    }
  });

  _exports.default = _default;
});