define("ccl-scoring-admin-ui/components/table-row-expanded/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oWFKrozU",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"d-flex flex-wrap justify-content-around\"],[8],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"table\",\"responsiveHiddenColumns\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"dl\",true],[10,\"class\",\"p-2 w-50\"],[8],[0,\"\\n\\n      \"],[7,\"dt\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"extra\",\"expandedLabel\"]]],null,{\"statements\":[[0,\"          \"],[1,[23,1,[\"extra\",\"expandedLabel\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[23,1,[\"label\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\\n      \"],[7,\"dd\",true],[8],[0,\"\\n\"],[4,\"if\",[[23,1,[\"cellComponent\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"component\",[[23,1,[\"cellComponent\"]]],[[\"row\",\"column\",\"value\"],[[24,[\"row\"]],[23,1,[]],[28,\"get\",[[24,[\"row\"]],[23,1,[\"valuePath\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"get\",[[24,[\"row\"]],[23,1,[\"valuePath\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/table-row-expanded/template.hbs"
    }
  });

  _exports.default = _default;
});