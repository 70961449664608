define("ccl-scoring-admin-ui/components/club-table/component", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    table: null,
    dir: "asc",
    sort: "date",
    enableSync: true,
    columns: Ember.computed(function () {
      return [{
        label: "ID",
        valuePath: "id",
        width: "40px",
        sortable: false
      }, {
        valuePath: "logo.sourceUrl",
        cellComponent: "table-club-logo",
        sortable: false,
        width: "40px"
      }, {
        label: "Name",
        valuePath: "name",
        cellComponent: "table-action-link"
      }, {
        label: "Status",
        valuePath: "status"
      }];
    }),

    init() {
      this._super(...arguments);

      const columns = Ember.get(this, "columns");
      const rows = Ember.get(this, "model");
      const enableSync = Ember.get(this, "enableSync");

      const table = _emberLightTable.default.create({
        columns,
        rows,
        enableSync
      });

      Ember.set(this, "table", table);
    }

  });

  _exports.default = _default;
});