define("ccl-scoring-admin-ui/components/club-staff-coaches/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'flashMessages': Ember.inject.service(),
    'cclGlobals': Ember.inject.service(),
    'store': Ember.inject.service(),

    getAvatarUrl(urls) {
      if (!urls) return;
      return urls[96];
    },

    'saveUser': (0, _emberConcurrency.task)(function* (model) {
      yield model.save();
      this.flashMessages.success('Coach Saved');
    }),
    'removeUser': (0, _emberConcurrency.task)(function* (model) {
      yield model.destroyRecord();
      this.flashMessages.success('Coach Removed');
    }),
    'searchUsers': (0, _emberConcurrency.task)(function* (term) {
      return yield this.store.query('user', {
        'search': term
      });
    }),

    addUser() {
      const manager = this.store.createRecord('club/coach', {
        'club': this.club
      });
      this.club.coaches.pushObject(manager);
    }

  });

  _exports.default = _default;
});