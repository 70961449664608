define("ccl-scoring-admin-ui/initializers/initialize-torii", ["exports", "torii/bootstrap/torii", "torii/configuration", "ccl-scoring-admin-ui/config/environment"], function (_exports, _torii, _configuration, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var initializer = {
    name: 'torii',

    initialize(application) {
      if (arguments[1]) {
        // Ember < 2.1
        application = arguments[1];
      }

      (0, _configuration.configure)(_environment.default.torii || {});
      (0, _torii.default)(application);
      application.inject('route', 'torii', 'service:torii');
    }

  };
  var _default = initializer;
  _exports.default = _default;
});