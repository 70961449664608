define("ccl-scoring-admin-ui/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rCB1f9t6",
    "block": "{\"symbols\":[\"flashItem\",\"yieldedComponent\",\"flash\",\"close\"],\"statements\":[[1,[22,\"head-layout\"],false],[0,\"\\n\\n\\n\"],[1,[28,\"page-title\",[\"CCL Admin\"],null],false],[0,\"\\n\\n\\n\"],[1,[28,\"liquid-outlet\",null,[[\"class\"],[[24,[\"cssClasses\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"uk-notify uk-notify-bottom-right\"],[8],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"flashMessages\",\"queue\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"flash-message\",null,[[\"flash\",\"class\"],[[23,1,[]],\"uk-notify-message\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,3,[\"componentName\"]]],null,{\"statements\":[[0,\"\\n        \"],[1,[28,\"component\",[[23,3,[\"componentName\"]]],[[\"flash\",\"close\"],[[23,3,[]],[23,4,[]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n        \"],[7,\"strong\",true],[8],[1,[23,2,[\"flashType\"]],false],[9],[0,\" \"],[1,[23,3,[\"message\"]],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[2,3,4]},null],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/application/template.hbs"
    }
  });

  _exports.default = _default;
});