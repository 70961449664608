define("ccl-scoring-admin-ui/components/table-cell-text-edit/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    click() {
      this._clickHandler();
    },

    touchStart() {
      this._clickHandler();
    },

    _clickHandler() {
      if (this.isEditing) return false;
      Ember.set(this, 'isEditing', true);
      return false;
    },

    'isEditing': false,
    'row': null,
    'model': Ember.computed.alias('row.content'),
    'save': (0, _emberConcurrency.task)(function* () {
      yield this.model.save();
      Ember.set(this, 'isEditing', false);
      return false;
    }),

    cancel() {
      this.model.rollbackAttributes();
      Ember.set(this, 'isEditing', false);
      return false;
    }

  });

  _exports.default = _default;
});