define("ccl-scoring-admin-ui/league/model", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'status': (0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Status'
    }),
    'name': (0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Name'
    })
  });

  var _default = Model.extend(Validations, {
    'name': attr('string'),
    'status': attr('string'),
    'scoringEnabled': attr('boolean'),
    'scheduleEnabled': attr('boolean')
  });

  _exports.default = _default;
});