define("ccl-scoring-admin-ui/components/match-bulk-edit/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'cclGlobals': Ember.inject.service(),
    'model': null,
    'homeClub': null,
    'awayClub': null,
    'homeTeam': null,
    'awayTeam': null,
    'season': null,
    'group': null,
    'gender': null,
    'status': null,
    'location': null,
    'field': null,
    'homeShirt': null,
    'homeShorts': null,
    'homeSocks': null,
    'awayShirt': null,
    'awayShorts': null,
    'awaySocks': null,
    'center': null,
    'matchDate': null,

    setHomeClub(item) {
      const models = this.model;
      Ember.set(this, 'homeClub', item);
      models.forEach(model => {
        Ember.set(model, 'clubOne', item);
      });
    },

    setAwayClub(item) {
      const models = this.model;
      Ember.set(this, 'awayClub', item);
      models.forEach(model => {
        Ember.set(model, 'clubTwo', item);
      });
    },

    setHomeTeam: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(200);
      const models = this.model;
      const item = this.homeTeam;
      models.forEach(model => {
        Ember.set(model, 'clubOneTeam', item);
      });
    }).keepLatest(),
    setAwayTeam: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(200);
      const models = this.model;
      const item = this.awayTeam;
      models.forEach(model => {
        Ember.set(model, 'clubTwoTeam', item);
      });
    }).keepLatest(),

    setSeason(item) {
      const models = this.model;
      Ember.set(this, 'season', item);
      models.forEach(model => {
        Ember.set(model, 'season', item);
      });
    },

    setGroup(item) {
      const models = this.model;
      Ember.set(this, 'group', item);
      models.forEach(model => {
        Ember.set(model, 'group', item);
      });
    },

    setGender(item) {
      const models = this.model;
      Ember.set(this, 'gender', item);
      models.forEach(model => {
        Ember.set(model, 'gender', item);
      });
    },

    setStatus(item) {
      const models = this.model;
      Ember.set(this, 'status', item);
      models.forEach(model => {
        Ember.set(model, 'status', item);
      });
    },

    setLocation(item) {
      const models = this.model;
      Ember.set(this, 'location', item);
      models.forEach(model => {
        Ember.set(model, 'location', item);
      });
    },

    setMatchDate(item) {
      const models = this.model;
      Ember.set(this, 'matchDate', item);
      models.forEach(model => {
        // saved to DB
        Ember.set(model, 'rescheduledDate', item.format('YYYY-MM-DD')); // Updates match table visual, only

        Ember.set(model, 'matchDate', item.format('YYYY-MM-DD'));
        Ember.set(model, 'status', 'rescheduled');
      });
    },

    setField: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(200);
      const models = this.model;
      const item = this.field;
      models.forEach(model => {
        Ember.set(model, 'field', item);
      });
    }).keepLatest(),
    setHomeShirt: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(200);
      const models = this.model;
      const item = this.homeShirt;
      models.forEach(model => {
        Ember.set(model, 'homeShirt', item);
      });
    }).keepLatest(),
    setHomeShorts: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(200);
      const models = this.model;
      const item = this.homeShorts;
      models.forEach(model => {
        Ember.set(model, 'homeShorts', item);
      });
    }).keepLatest(),
    setHomeSocks: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(200);
      const models = this.model;
      const item = this.homeSocks;
      models.forEach(model => {
        Ember.set(model, 'homeSocks', item);
      });
    }).keepLatest(),
    setAwayShirt: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(200);
      const models = this.model;
      const item = this.awayShirt;
      models.forEach(model => {
        Ember.set(model, 'awayShirt', item);
      });
    }).keepLatest(),
    setAwayShorts: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(200);
      const models = this.model;
      const item = this.awayShorts;
      models.forEach(model => {
        Ember.set(model, 'awayShorts', item);
      });
    }).keepLatest(),
    setAwaySocks: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(200);
      const models = this.model;
      const item = this.awaySocks;
      models.forEach(model => {
        Ember.set(model, 'awaySocks', item);
      });
    }).keepLatest(),

    didInsertElement() {
      this._super();

      const models = this.model;
      models.forEach(model => {
        Ember.get(model, 'content').startTrack();
      });
    }

  });

  _exports.default = _default;
});