define("ccl-scoring-admin-ui/components/flash-session-control/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LZ3BIqiD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"card-header\"],[8],[0,\"Heads up!\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"card-body\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"card-text\"],[8],[1,[24,[\"flash\",\"message\"]],false],[9],[0,\"\\n    \"],[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"success\",[28,\"perform\",[[24,[\"login\"]]],null]]],{\"statements\":[[0,\"Stay Logged In\"]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"danger\",[28,\"action\",[[23,0,[]],[24,[\"session\",\"invalidate\"]]],null]]],{\"statements\":[[0,\"Log Out Now\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/flash-session-control/template.hbs"
    }
  });

  _exports.default = _default;
});