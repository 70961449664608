define("ccl-scoring-admin-ui/components/season-results/component", ["exports", "ccl-scoring-admin-ui/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'season': 0,
    'mode': 'default',
    'model': [],
    'meta': null,
    'ajax': Ember.inject.service(),
    'fetch': (0, _emberConcurrency.task)(function* () {
      const ajax = this.ajax;
      const season = this.season;
      const results = yield ajax.request("/seasons/".concat(season, "/results"), {
        'host': _environment.default.apiHost,
        'namespace': '/ccl-scoring/v1',
        'contentType': 'application/json; charset=utf-8'
      });
      Ember.set(this, 'model', results.data.results);
      Ember.set(this, 'meta', results.data.parameters);
    }).drop(),

    init() {
      const fetch = this.fetch;

      this._super();

      fetch.perform();
    }

  });

  _exports.default = _default;
});