define("ccl-scoring-admin-ui/components/table-club-logo/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vQ8cFUbe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[10,\"alt\",\"club logo\"],[10,\"class\",\"img-fluid\"],[11,\"src\",[22,\"value\"]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/table-club-logo/template.hbs"
    }
  });

  _exports.default = _default;
});