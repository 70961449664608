define("ccl-scoring-admin-ui/mixins/save-model", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    'router': Ember.inject.service(),
    'flashMessages': Ember.inject.service(),
    'cclGlobals': Ember.inject.service(),
    'rollbar': Ember.inject.service(),

    revertModel() {
      this.model.rollbackAttributes();
      this.flashMessages.success('Changes Canceled');
      return false;
    },

    'saveModel': (0, _emberConcurrency.task)(function* () {
      try {
        const model = this.model;
        const router = this.router;
        const flash = this.flashMessages;
        if (Ember.get(model, 'isNew')) return;
        const {
          validations
        } = yield model.validate();

        if (Ember.get(validations, 'isValid')) {
          const result = yield model.save();

          if (Ember.get(model, 'isNew')) {
            flash.success('Created');
            yield (0, _emberConcurrency.timeout)(200);
            return router.transitionTo("".concat(model.modelName, ".edit"), result);
          } else {
            flash.success('Updated');
          }
        }
      } catch (e) {
        this.rollbar.error(e.message, e);
        this.flashMessages.error(e.message);
      }
    }).enqueue(),

    selectClasses(prop, classes) {
      if ('error' === prop) {
        classes.push('is-invalid');
      }

      return classes ? classes.join(' ') : '';
    }

  });

  _exports.default = _default;
});