define("ccl-scoring-admin-ui/components/leaderboard-widgets/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    enabledSeasons: null,
    currentlyEditing: null,

    addWidget() {
      const widget = this.store.createRecord('widgetLeaderboard', {
        'user': this.currentUser.user
      });
      Ember.set(this, 'currentlyEditing', widget);
    },

    editWidget(model) {
      Ember.set(this, 'currentlyEditing', model);
    },

    removeWidget: (0, _emberConcurrency.task)(function* () {
      this.currentUser.user.cclLeaderboardWidgets.removeObject(this.currentlyEditing);
      yield this.currentUser.user.save();
      Ember.set(this, 'currentlyEditing', null);
    }),
    saveWidget: (0, _emberConcurrency.task)(function* () {
      yield this.currentUser.user.save();
      Ember.set(this, 'currentlyEditing', null);
    }),
    reorderWidgets: (0, _emberConcurrency.task)(function* (itemModels) {
      Ember.set(this, 'currentUser.user.cclLeaderboardWidgets', itemModels);
      yield this.currentUser.user.save();
    }),
    fetch: (0, _emberConcurrency.task)(function* () {
      const response = yield this.store.query('season', {
        status: 'enabled'
      });
      this.enabledSeasons.pushObjects(response.toArray());
    }),

    isCCL(season) {
      return 2 !== Ember.get(season, 'scoringMethod');
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.fetch.perform();
    },

    init() {
      this._super(...arguments);

      Ember.set(this, 'enabledSeasons', []);
    }

  });

  _exports.default = _default;
});