define("ccl-scoring-admin-ui/components/table-cell-text-edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n6dvmonl",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"liquid-if\",[[24,[\"isEditing\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"bs-form\",null,[[\"formLayout\",\"model\"],[\"vertical\",[24,[\"row\"]]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"element\"]],\"expected `form.element` to be a contextual component but found a string. Did you mean `(component form.element)`? ('ccl-scoring-admin-ui/components/table-cell-text-edit/template.hbs' @ L9:C6) \"],null]],[[\"controlType\",\"property\"],[[24,[\"column\",\"extra\",\"controlType\"]],[24,[\"column\",\"valuePath\"]]]]],false],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"d-flex\"],[8],[0,\"\\n\\n\"],[4,\"bs-button\",null,[[\"type\",\"size\",\"onClick\"],[\"primary\",\"xs\",[28,\"perform\",[[24,[\"save\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"save\",\"isIdle\"]]],null,{\"statements\":[[0,\"\\n          \"],[1,[28,\"fa-icon\",[\"check\"],[[\"fixedWidth\"],[true]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n          \"],[1,[28,\"fa-icon\",[\"spinner\"],[[\"pulse\",\"fixedWidth\"],[true,true]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"bs-button\",null,[[\"type\",\"size\",\"onClick\"],[\"warning\",\"xs\",[28,\"action\",[[23,0,[]],[24,[\"cancel\"]]],null]]],{\"statements\":[[0,\"\\n        \"],[1,[28,\"fa-icon\",[\"times\"],[[\"fixedWidth\"],[true]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[2,\".d-flex\"],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[7,\"span\",true],[8],[1,[22,\"value\"],false],[9],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/table-cell-text-edit/template.hbs"
    }
  });

  _exports.default = _default;
});