define("ccl-scoring-admin-ui/league/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iqrgK/a3",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Leagues\"],null],false],[0,\"\\n\\n\"],[1,[22,\"nav-top\"],false],[0,\"\\n\\n\"],[1,[22,\"nav-side\"],false],[0,\"\\n\\n\"],[1,[22,\"liquid-outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/league/template.hbs"
    }
  });

  _exports.default = _default;
});