define("ccl-scoring-admin-ui/instance-initializers/rsvp-rollbar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    let rollbarService = appInstance.lookup('service:rollbar');
    Ember.RSVP.on('error', function (e) {
      rollbarService.error(e.message, e);
    });
  }

  var _default = {
    name: 'rsvp-rollbar',
    initialize
  };
  _exports.default = _default;
});