define("ccl-scoring-admin-ui/result/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    'ga': attr('number'),
    'gf': attr('number'),
    'gd': Ember.computed('ga', 'gf', function () {
      return this.gf - this.ga;
    }),
    'gender': attr('string'),
    'losses': attr('number'),
    'ties': attr('number'),
    'wins': attr('number'),
    'publicPoints': attr('number'),
    'privatePoints': attr('number'),
    'championshipPoints': attr('number'),
    'club': belongsTo('club', {
      inverse: null
    }),
    'group': belongsTo('group', {
      inverse: null
    }),
    'season': belongsTo('season', {
      inverse: null
    })
  });

  _exports.default = _default;
});