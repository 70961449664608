define("ccl-scoring-admin-ui/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    'routeAfterAuthentication': 'leaderboard',
    'routeIfAlreadyAuthenticated': 'leaderboard',
    'authenticationRoute': 'index',
    'currentUser': Ember.inject.service(),

    beforeModel() {
      return this._loadCurrentUser();
    },

    sessionAuthenticated() {
      this._super(...arguments);

      this.currentUser.setExpiresAt();

      this._loadCurrentUser();
    },

    _loadCurrentUser() {
      this.currentUser.load.perform();
      this.currentUser.pollSession.perform();
    }

  });

  _exports.default = _default;
});