define("ccl-scoring-admin-ui/components/select-season-scoring/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b1jRTg/s",
    "block": "{\"symbols\":[\"method\"],\"statements\":[[4,\"power-select\",null,[[\"options\",\"allowClear\",\"selected\",\"placeholder\",\"triggerClass\",\"onchange\"],[[24,[\"model\"]],true,[24,[\"selectedOption\"]],\"Scoring Method\",\"form-control\",[28,\"action\",[[23,0,[]],\"selected\"],null]]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/select-season-scoring/template.hbs"
    }
  });

  _exports.default = _default;
});