define("ccl-scoring-admin-ui/components/location-edit/component", ["exports", "ccl-scoring-admin-ui/mixins/save-model", "ember-concurrency"], function (_exports, _saveModel, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_saveModel.default, {
    'ajax': Ember.inject.service(),
    'flashMessages': Ember.inject.service(),
    'rollbar': Ember.inject.service(),
    'model': null,
    'zoom': 16,
    'apiKey': 'AIzaSyDrMFg2x_HXsDRwlWCRJwJepP2FSB6LB_U',
    'hasMap': Ember.computed.and('model.latitude', 'model.longitude'),
    'actions': {
      updateLatLong(marker) {
        const coord = marker.target.getLatLng();
        const model = this.model;
        Ember.set(model, 'latitude', coord.lat);
        Ember.set(model, 'longitude', coord.lng);
      }

    },
    'geocodeAddress': (0, _emberConcurrency.task)(function* () {
      try {
        const ajax = this.ajax;
        const model = this.model;
        const address = Ember.get(model, 'address');
        const urlBase = 'https://maps.googleapis.com/maps/api/geocode/json?';
        const {
          results,
          status
        } = yield ajax.request(urlBase + "address=".concat(address, "&key=").concat(this.apiKey));

        if ('OK' === status) {
          model.setProperties({
            'latitude': results[0].geometry.location.lat,
            'longitude': results[0].geometry.location.lng
          });
        }
      } catch (e) {
        this.rollbar.error(e.message, e);
      }
    })
  });

  _exports.default = _default;
});