define("ccl-scoring-admin-ui/current-user/service", ["exports", "ccl-scoring-admin-ui/config/environment", "ember-concurrency"], function (_exports, _environment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    '_pollInterval': 300000,
    // 5 minutes
    'user': null,
    'ajax': Ember.inject.service(),
    'session': Ember.inject.service(),
    'flashMessages': Ember.inject.service(),
    'store': Ember.inject.service(),
    'rollbar': Ember.inject.service(),
    'endpoint': 'oauth/me',
    'headers': Ember.computed('session.isAuthenticated', {
      get() {
        let headers = {};
        const token = Ember.get(this, 'session.data.authenticated.authorizationToken.access_token');

        if (token) {
          headers['Authorization'] = "Bearer ".concat(token);
        }

        return headers;
      }

    }),
    'load': (0, _emberConcurrency.task)(function* () {
      const host = _environment.default.apiHost;

      if (!Ember.get(this, 'session.isAuthenticated')) {
        return this.session.invalidate();
      }

      try {
        const user = yield this.ajax.request("".concat(host, "/").concat(this.endpoint, "/"), {
          'headers': this.headers
        });
        Ember.set(this, 'rollbar.currentUser', {
          id: user.ID,
          email: user.user_email,
          username: user.display_name
        });
        const record = yield this.store.findRecord('user', user['ID']);
        Ember.set(this, 'user', record);
      } catch (e) {
        this.session.invalidate();
      }
    }),
    'save': (0, _emberConcurrency.task)(function* () {
      try {
        return yield this.user.save();
      } catch (e) {
        this.rollbar.error(e.message, e);
      }
    }),

    setExpiresAt() {
      const sessionLength = Ember.get(this, 'session.data.authenticated.authorizationToken.expires_in');
      const sessionExpires = Date.now() + sessionLength * 1000;
      this.session.set('data.expiresAt', sessionExpires);
    },

    'pollSession': (0, _emberConcurrency.task)(function* () {
      const session = this.session;
      const messages = this.flashMessages;
      const setExpiresAt = this.setExpiresAt;

      while (true) {
        let now = Date.now();
        let exp = Ember.get(this, 'session.data.expiresAt');
        let diff = (exp - now) / 1000 / 60;

        if (0 > diff) {
          session.invalidate();
        } else if (10 > diff) {
          if (!messages.queue.isAny('type', 'sessionControl')) {
            messages.add({
              message: "Your session will expire in ".concat(Math.round(diff), " minutes. Would you like to continue?"),
              type: 'sessionControl',
              timeout: 20000,
              destroyOnClick: false,
              componentName: 'flashSessionControl',
              setExpiresAt: setExpiresAt.bind(this)
            });
          }
        } else {// do nothing    
        }

        yield (0, _emberConcurrency.timeout)(this._pollInterval);
      }
    }).cancelOn('session.invalidationSucceeded').restartable()
  });

  _exports.default = _default;
});