define("ccl-scoring-admin-ui/club/coach/adapter", ["exports", "ccl-scoring-admin-ui/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    pathForType()
    /* modelName */
    {
      return 'clubs/staff';
    }

  });

  _exports.default = _default;
});