define("ccl-scoring-admin-ui/club/model", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'name': (0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Name'
    }),
    'status': (0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Status'
    }),
    'website': {
      'description': 'Website',
      'validators': [(0, _emberCpValidations.validator)('format', {
        'allowBlank': true,
        'type': 'url'
      })]
    }
  });

  var _default = Model.extend(Validations, {
    'name': attr('string'),
    'status': attr('string'),
    'website': attr('string'),
    'homeShirt': attr('string'),
    'homeShorts': attr('string'),
    'homeSocks': attr('string'),
    'awayShirt': attr('string'),
    'awayShorts': attr('string'),
    'awaySocks': attr('string'),
    'womenHomeShirt': attr('string'),
    'womenHomeShorts': attr('string'),
    'womenHomeSocks': attr('string'),
    'womenAwayShirt': attr('string'),
    'womenAwayShorts': attr('string'),
    'womenAwaySocks': attr('string'),
    'teams': attr(),
    'managers': hasMany('club/manager'),
    'coaches': hasMany('club/coach'),
    'logo': belongsTo('media', {
      'inverse': null
    }),
    'clubManager': Ember.computed.alias('manager.firstObject.user')
  });

  _exports.default = _default;
});