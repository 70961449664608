define("ccl-scoring-admin-ui/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    'session': Ember.inject.service(),
    actions: {
      login() {
        const session = this.session;
        return session.authenticate('authenticator:torii', 'wordpress-oauth2');
      }

    }
  });

  _exports.default = _default;
});