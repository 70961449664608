define("ccl-scoring-admin-ui/components/match-filter-gender/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    genders: null,

    init() {
      this._super(...arguments);

      Ember.set(this, 'genders', ['male', 'female']);
    }

  });

  _exports.default = _default;
});