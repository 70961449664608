define("ccl-scoring-admin-ui/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    'cclGlobals': Ember.inject.service(),
    'session': Ember.inject.service(),
    'collapsed': Ember.computed.alias('cclGlobals.navCollapse'),
    'cssClasses': Ember.computed('routeStyleNamespaceClassSet', function () {
      const routeClasses = this.routeStyleNamespaceClassSet;
      return routeClasses + ' wrapper';
    })
  });

  _exports.default = _default;
});