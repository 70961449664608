define("ccl-scoring-admin-ui/app", ["exports", "ccl-scoring-admin-ui/resolver", "ember-load-initializers", "ccl-scoring-admin-ui/config/environment", "ember-inflector"], function (_exports, _resolver, _emberLoadInitializers, _environment, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  const inflector = _emberInflector.default.inflector;
  inflector.irregular('media', 'media'); // Ember.onerror = function (error) {
  //   console.log('Ember.onerror handler', error.message);
  // };
  // Ember.RSVP.on('error', function (error) {
  //   console.log('Ember.RSVP error handler', error);
  // });
  // Ember.Logger.error = function (message, cause, stack) {
  //   console.log('Ember.Logger.error handler', message, cause, stack);
  // };

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});