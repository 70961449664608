define("ccl-scoring-admin-ui/group/model", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'name': (0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Name'
    }),
    'status': (0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Status'
    }),
    'slug': (0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Slug'
    }),
    'matchMinutes': [(0, _emberCpValidations.validator)('number', {
      'allowString': true,
      'integer': true,
      'description': 'Match Minutes'
    })],
    'flexMinutes': [(0, _emberCpValidations.validator)('number', {
      'allowString': true,
      'integer': true,
      'description': 'Flex Minutes'
    })]
  });

  var _default = Model.extend(Validations, {
    'name': attr('string'),
    'status': attr('string'),
    'slug': attr('string'),
    'matchMinutes': attr('number'),
    'flexMinutes': attr('number')
  });

  _exports.default = _default;
});