define("ccl-scoring-admin-ui/widget-leaderboard-result/model", ["exports", "ember-data", "ember-cp-validations", "ccl-scoring-admin-ui/widget-leaderboard/model"], function (_exports, _emberData, _emberCpValidations, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'title': {
      'description': 'Title',
      'validators': [(0, _emberCpValidations.validator)('presence', true)]
    },
    'season': {
      'description': 'Season',
      'validators': [(0, _emberCpValidations.validator)('belongs-to')]
    },
    'group': {
      'description': 'Group',
      'validators': [(0, _emberCpValidations.validator)('inline', {
        validate(value, options) {
          if ('championship' === this.model.mode) return true;
          if (this.model.group) return true;
          return "".concat(options.description, " must be selected");
        }

      })]
    },
    'gender': {
      'description': 'Gender',
      'validators': [(0, _emberCpValidations.validator)('inline', {
        validate(value, options) {
          if ('championship' === this.model.mode) return true;
          if (this.model.gender) return true;
          return "".concat(options.description, " must be selected");
        }

      })]
    }
  });

  var _default = _model.default.extend(Validations, {
    'season': belongsTo(),
    'group': belongsTo(),
    'gender': attr('string'),
    'mode': attr('string')
  });

  _exports.default = _default;
});