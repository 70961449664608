define("ccl-scoring-admin-ui/components/table-dq-checked/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      removeRelationship() {
        const dq = this.dqId;
        const home = this.homeDqId;
        const away = this.awayDqId;
        const row = Ember.get(this, 'row.content');
        const model = Ember.get(this, 'column.model');

        if (home == dq) {
          Ember.set(row, 'dqOne', null);
          Ember.get(model, 'matchesHome').removeObject(row);
        }

        if (away == dq) {
          Ember.set(row, 'dqTwo', null);
          Ember.get(model, 'matchesAway').removeObject(row);
        }

        this.saveAll.perform();
      },

      addRelationship() {
        const dqClubId = this.dqClubId;
        const homeClubId = this.homeClubId;
        const awayClubId = this.awayClubId;
        const row = Ember.get(this, 'row.content');
        const model = Ember.get(this, 'column.model');

        if (dqClubId == homeClubId) {
          Ember.set(row, 'dqOne', model);
        }

        if (dqClubId == awayClubId) {
          Ember.set(row, 'dqTwo', model);
        }

        this.saveAll.perform();
      }

    },
    'saveAll': (0, _emberConcurrency.task)(function* () {
      const dq = Ember.get(this, 'column.model');
      const match = Ember.get(this, 'row.content');
      yield dq.save();
      yield match.save();
    }).enqueue(),
    'dqId': Ember.computed.alias('column.model.id'),
    'dqClubId': Ember.computed.alias('column.model.club.id'),
    'homeClubId': Ember.computed.alias('row.clubOne.id'),
    'awayClubId': Ember.computed.alias('row.clubTwo.id'),
    'homeDqId': Ember.computed.alias('row.dqOne.id'),
    'awayDqId': Ember.computed.alias('row.dqTwo.id'),
    'selected': Ember.computed('row.dqOne', 'row.dqTwo', function () {
      const dq = this.dqId;
      const home = this.homeDqId;
      const away = this.awayDqId;
      return dq == home || dq == away;
    })
  });

  _exports.default = _default;
});