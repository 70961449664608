define("ccl-scoring-admin-ui/result/adapter", ["exports", "ccl-scoring-admin-ui/application/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend({
    urlForQuery(query) {
      return this._super(query, "seasons/".concat(query.season, "/results"));
    }

  });

  _exports.default = _default;
});