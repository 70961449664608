define("ccl-scoring-admin-ui/components/nav-top/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'tagName': 'header',
    'classNames': ['topnavbar-wrapper'],
    'toggleBreakpoint': 'xl',
    'navSide': Ember.inject.service(),

    navSidetoggle() {
      this.navSide.toggle();
    }

  });

  _exports.default = _default;
});