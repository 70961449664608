define("ccl-scoring-admin-ui/components/light-table/cells/match-status", ["exports", "ember-light-table/components/cells/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    'classNames': ['match-status'],
    'classNameBindings': ['backgroundClass'],
    'backgroundClass': Ember.computed('value', function () {
      const value = Ember.get(this, 'value');
      let bgClass = 'white';

      switch (value) {
        case 'final':
          bgClass = 'success';
          break;

        case 'scheduled':
          bgClass = 'primary';
          break;

        case 'rescheduled':
          bgClass = 'warning';
          break;

        case 'unofficial':
          bgClass = 'info';
          break;

        case 'trash':
          bgClass = 'danger';
          break;
      }

      return "bg-".concat(bgClass);
    })
  });

  _exports.default = _default;
});