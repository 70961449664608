define("ccl-scoring-admin-ui/components/date-picker-month", ["exports", "ember-date-components/components/date-picker-month"], function (_exports, _datePickerMonth) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _datePickerMonth.default;
    }
  });
});