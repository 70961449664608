define("ccl-scoring-admin-ui/season/index/controller", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    rollbar: Ember.inject.service(),
    page: 0,
    perPage: 30,
    canLoadMore: Ember.computed.bool('meta.links.next'),
    isLoading: Ember.computed.oneWay('fetchRecords.isRunning'),
    meta: null,

    onScrolledToBottom() {
      if (this.canLoadMore && this.fetchRecords.isIdle) {
        this.incrementProperty('page');
        this.fetchRecords.perform();
      }
    },

    fetchRecords: (0, _emberConcurrency.task)(function* () {
      try {
        const model = this.model;
        yield (0, _emberConcurrency.timeout)(250);
        const params = Object.assign({}, {
          'page': this.page
        });
        const records = yield this.store.query('season', params);
        const meta = Ember.get(records, 'meta');
        model.pushObjects(records.toArray());
        Ember.set(this, 'meta', meta);
      } catch (e) {
        this.rollbar.error(e.message, e);
      }
    }).enqueue(),

    init() {
      this._super(...arguments);

      Ember.set(this, 'model', []);
      Ember.set(this, 'meta', {
        'links': {
          'next': true
        }
      });
    }

  });

  _exports.default = _default;
});