define("ccl-scoring-admin-ui/components/table-dq-checked/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4763ZkQU",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"selected\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"bs-button\",null,[[\"type\",\"size\",\"onClick\"],[[24,[\"primary\"]],\"xs\",[28,\"action\",[[23,0,[]],\"removeRelationship\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"check-square\"],[[\"fixedWidth\"],[true]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n\"],[4,\"bs-button\",null,[[\"type\",\"size\",\"onClick\"],[[24,[\"primary\"]],\"xs\",[28,\"action\",[[23,0,[]],\"addRelationship\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"square\"],[[\"fixedWidth\"],[true]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/table-dq-checked/template.hbs"
    }
  });

  _exports.default = _default;
});