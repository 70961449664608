define("ccl-scoring-admin-ui/widget-leaderboard-result/adapter", ["exports", "ccl-scoring-admin-ui/widget-leaderboard/adapter"], function (_exports, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend();

  _exports.default = _default;
});