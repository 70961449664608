define("ccl-scoring-admin-ui/wp-format-response/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    mediaAdapter(item) {
      return {
        'id': item.id,
        'type': 'media',
        'attributes': {
          'alt-text': item.alt_text,
          'caption': item.caption ? item.caption.rendered : null,
          'date': item.date,
          'date-gmt': item.date_gmt,
          'description': item.description ? item.description.rendered : null,
          'guid': item.guid ? item.guid.rendered : null,
          'link': item.link,
          'media-details': item.media_details,
          'media-type': item.media_type,
          'mime-type': item.mime_type,
          'modified': item.modified,
          'modified-gmt': item.modified_gmt,
          'ping-status': item.ping_status,
          'slug': item.slug,
          'source-url': item.source_url,
          'status': item.status,
          'title': item.title ? item.title.rendered : null,
          'type': item.type
        },
        'relationships': {
          'author': {
            'data': {
              'id': item.author,
              'type': 'user'
            }
          }
        }
      };
    },

    mediaAjax(item) {
      const mediaModel = this.mediaAdapter(item);
      const attributes = {};

      for (let attr in mediaModel.attributes) {
        let key = Ember.String.camelize(attr);
        attributes[key] = mediaModel.attributes[attr];
      }

      return Object.assign({}, {
        'id': item.id,
        'author': item.author
      }, attributes);
    }

  });

  _exports.default = _default;
});