define("ccl-scoring-admin-ui/components/nav-side/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'tagName': 'aside',
    'classNames': ['aside-container'],
    'currentUser': Ember.inject.service(),
    'navSide': Ember.inject.service(),
    'session': Ember.inject.service(),

    didInsertElement() {
      this._super(...arguments); // Super hacky way of removing conflicting class names
      // inherited from ember-bootstrap


      const navBar = this.element.querySelector('nav.sidebar');
      navBar.classList.remove('navbar');
      navBar.classList.remove('navbar-light');
    }

  });

  _exports.default = _default;
});