define("ccl-scoring-admin-ui/widget-leaderboard/adapter", ["exports", "ember-data", "uuid"], function (_exports, _emberData, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    generateIdForRecord() {
      return (0, _uuid.v4)();
    }

  });

  _exports.default = _default;
});