define("ccl-scoring-admin-ui/location/model", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'name': (0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Name'
    }),
    'status': (0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Status'
    }),
    'address': (0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Address'
    }),
    'latitude': [(0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Latitude'
    }), (0, _emberCpValidations.validator)('number', {
      'allowString': true,
      'description': 'Latitude'
    })],
    'longitude': [(0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Longitude'
    }), (0, _emberCpValidations.validator)('number', {
      'allowString': true,
      'description': 'Longitude'
    })]
  });

  var _default = Model.extend(Validations, {
    'name': attr('string'),
    'status': attr('string'),
    'address': attr('string'),
    'latitude': attr('number'),
    'longitude': attr('number')
  });

  _exports.default = _default;
});