define("ccl-scoring-admin-ui/components/list-season-conferences/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dd'],
    'cclGlobals': Ember.inject.service(),
    store: Ember.inject.service(),

    addConference(item) {
      const record = this.store.createRecord('conference', {
        'name': item.name,
        'group': item
      });
      this.model.pushObject(record);
    },

    removeConference(item) {
      const model = this.model;
      model.removeObject(item);
    },

    addClub(conference, club) {
      conference.clubs.pushObject(club);
    },

    removeClub(conference, club) {
      conference.clubs.removeObject(club);
    },

    toggleMaleScoring(conference, club) {
      const list = conference.maleClubs;
      const isMember = list.includes(club);
      isMember ? list.removeObject(club) : list.pushObject(club);
    },

    toggleFemaleScoring(conference, club) {
      const list = conference.femaleClubs;
      const isMember = list.includes(club);
      isMember ? list.removeObject(club) : list.pushObject(club);
    }

  });

  _exports.default = _default;
});