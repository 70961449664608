define("ccl-scoring-admin-ui/components/season-edit/component", ["exports", "ccl-scoring-admin-ui/mixins/save-model"], function (_exports, _saveModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_saveModel.default, {
    'model': null,
    'momentFormat': 'YYYY-MM-DD',
    'center': null,
    'cclGlobals': Ember.inject.service(),
    actions: {
      setLeague(league) {
        const model = this.model;
        Ember.set(model, 'league', league);
      },

      updateStartDate(moment) {
        const model = this.model;
        const format = this.momentFormat;
        Ember.set(model, 'startDate', moment.format(format));
      }

    },

    setScoringMethod(method) {
      Ember.set(this.model, 'scoringMethod', method.id);
    },

    init() {
      this._super(...arguments);

      Ember.set(this, 'clubs', []);
      Ember.set(this, 'groups', []);
    }

  });

  _exports.default = _default;
});