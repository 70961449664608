define("ccl-scoring-admin-ui/disqualification/model", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'season': [(0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Season'
    }), (0, _emberCpValidations.validator)('belongs-to', {
      'description': 'Season'
    })],
    'group': [(0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Group'
    }), (0, _emberCpValidations.validator)('belongs-to', {
      'description': 'Group'
    })],
    'club': [(0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Club'
    }), (0, _emberCpValidations.validator)('belongs-to', {
      'description': 'Club'
    })]
  });

  var _default = Model.extend(Validations, {
    'gender': attr('string'),
    'note': attr('string'),
    'replacement': attr('boolean'),
    'season': belongsTo(),
    'club': belongsTo(),
    'group': belongsTo(),
    'league': belongsTo(),
    'matchesHome': hasMany('match', {
      'inverse': 'dqOne'
    }),
    'matchesAway': hasMany('match', {
      'inverse': 'dqTwo'
    })
  });

  _exports.default = _default;
});