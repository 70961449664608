define("ccl-scoring-admin-ui/components/x-checkbox-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iQjPQ4KI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[10,\"class\",\"switch switch-lg\"],[8],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"type\",\"name\",\"change\"],[\"checkbox\",[24,[\"name\"]],[28,\"action\",[[23,0,[]],[24,[\"change\"]]],null]]]],false],[0,\"\\n    \"],[7,\"span\",true],[8],[1,[28,\"if\",[[24,[\"label\"]],[24,[\"label\"]]],null],false],[9],[0,\"\\n\"],[9],[0,\"   \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/x-checkbox-switch/template.hbs"
    }
  });

  _exports.default = _default;
});