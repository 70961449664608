define("ccl-scoring-admin-ui/season/model", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'name': {
      'description': 'Season name',
      'validators': [(0, _emberCpValidations.validator)('presence', true)]
    },
    'status': {
      'description': 'Status',
      'validators': [(0, _emberCpValidations.validator)('presence', true)]
    },
    'startDate': {
      'description': 'Start date',
      'validators': [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
        'precision': 'day',
        'format': 'YYYY-MM-DD',
        'description': 'Date'
      })]
    },
    'scoringMethod': {
      'description': 'Scoring Method',
      'validators': [(0, _emberCpValidations.validator)('presence', true)]
    },
    'league': {
      'description': 'League',
      'validators': [(0, _emberCpValidations.validator)('belongs-to')]
    },
    'groups': {
      'description': 'Groups',
      'validators': [(0, _emberCpValidations.validator)('collection', true)]
    },
    'clubs': {
      'description': 'Clubs',
      'validators': [(0, _emberCpValidations.validator)('collection', true)]
    }
  });

  var _default = Model.extend(Validations, {
    'name': attr('string'),
    'status': attr('string'),
    'startDate': attr('string'),
    'scoringMethod': attr('number'),
    'league': belongsTo('league', {
      'inverse': null
    }),
    'groups': hasMany('group', {
      'inverse': null
    }),
    'scoredGroups': hasMany('group', {
      'inverse': null
    }),
    'clubs': hasMany('club', {
      'inverse': null
    }),
    'conferences': hasMany('conference', {
      'inverse': null
    })
  });

  _exports.default = _default;
});