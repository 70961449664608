define("ccl-scoring-admin-ui/components/match-filter-multiple/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    selected: Ember.computed('ids', function () {
      return this.ids.map(id => {
        return this.store.findRecord(this.modelName, id);
      });
    })
  });

  _exports.default = _default;
});