define("ccl-scoring-admin-ui/components/table-season-clubs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nTQkuxV5",
    "block": "{\"symbols\":[\"item\",\"club\"],\"statements\":[[7,\"table\",true],[10,\"class\",\"table table-condensed\"],[8],[0,\"\\n    \"],[7,\"thead\",true],[8],[0,\"\\n        \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"th\",true],[8],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Club\"],[9],[0,\"\\n            \"],[7,\"th\",true],[8],[0,\"Remove\"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"tbody\",true],[8],[0,\"\\n\"],[4,\"each\",[[28,\"sort-by\",[\"name\",[24,[\"model\"]]],null]],null,{\"statements\":[[0,\"            \"],[7,\"tr\",true],[8],[0,\"\\n                \"],[7,\"td\",true],[8],[7,\"img\",true],[10,\"alt\",\"club logo\"],[10,\"class\",\"img-fluid\"],[11,\"src\",[23,2,[\"logo\",\"sourceUrl\"]]],[8],[9],[9],[0,\"\\n                \"],[7,\"td\",true],[8],[1,[23,2,[\"name\"]],false],[9],[0,\"\\n                \"],[7,\"td\",true],[8],[0,\"\\n\"],[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"danger\",[28,\"action\",[[23,0,[]],[24,[\"remove\"]],[23,2,[]]],null]]],{\"statements\":[[0,\"                        \"],[1,[28,\"fa-icon\",[\"times\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"                \"],[9],[0,\"\\n            \"],[9],[0,\"                \\n\"]],\"parameters\":[2]},null],[0,\"        \"],[7,\"tr\",true],[8],[0,\"\\n            \"],[7,\"td\",true],[8],[0,\"Add\"],[9],[0,\"\\n            \"],[7,\"td\",true],[10,\"colspan\",\"2\"],[8],[0,\"\\n\"],[4,\"power-select\",null,[[\"placeholder\",\"onchange\",\"loadingMessage\",\"placeholder\",\"searchField\",\"search\"],[\"Add Club\",[28,\"action\",[[23,0,[]],[24,[\"add\"]]],null],\"loading...\",\"Type to search\",\"name\",[28,\"perform\",[[24,[\"cclGlobals\",\"searchClubs\"]]],null]]],{\"statements\":[[0,\"\\n                    \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/table-season-clubs/template.hbs"
    }
  });

  _exports.default = _default;
});