define("ccl-scoring-admin-ui/components/model-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "A8wYwkZl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"col-12\"],[8],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"card card-default\"],[8],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"card-header\"],[8],[0,\"Actions & Messages\"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"card-body\"],[8],[0,\"\\n\\n          \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n            \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n\\n              \"],[1,[28,\"bs-button\",null,[[\"type\",\"defaultText\",\"pendingText\",\"fulfilledText\",\"rejectedText\",\"disabled\",\"onClick\"],[\"primary\",\"Save\",\"Saving...\",\"Saved\",\"Couldn't Save\",[24,[\"model\",\"validations\",\"isInvalid\"]],[28,\"perform\",[[24,[\"saveModel\"]]],null]]]],false],[0,\"\\n\\n              \"],[1,[28,\"bs-button\",null,[[\"type\",\"defaultText\",\"disabled\",\"onClick\"],[\"warning\",\"Cancel\",[24,[\"model\",\"isNew\"]],[28,\"action\",[[23,0,[]],[24,[\"revertModel\"]]],null]]]],false],[0,\"\\n\\n\"],[4,\"bs-button\",null,[[\"type\",\"disabled\",\"onClick\"],[\"info\",[24,[\"model\",\"validations\",\"isValid\"]],[28,\"perform\",[[24,[\"showMessages\"]]],null]]],{\"statements\":[[0,\"\\n                Messages \"],[7,\"span\",true],[10,\"class\",\"badge badge-light\"],[8],[1,[24,[\"model\",\"validations\",\"messages\",\"length\"]],false],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n              \"],[14,1],[0,\"\\n\\n            \"],[9],[2,\".col\"],[0,\"\\n\\n          \"],[9],[2,\".row\"],[0,\"\\n\\n        \"],[9],[2,\".card-body\"],[0,\"\\n\\n      \"],[9],[2,\".card\"],[0,\"\\n\\n    \"],[9],[2,\".col\"],[0,\"\\n\\n  \"],[9],[2,\".row\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/model-actions/template.hbs"
    }
  });

  _exports.default = _default;
});