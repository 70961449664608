define("ccl-scoring-admin-ui/components/photo-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nOvaT7fY",
    "block": "{\"symbols\":[\"dropzone\",\"queue\"],\"statements\":[[4,\"file-dropzone\",null,[[\"name\"],[\"photos\"]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"active\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[23,1,[\"valid\"]]],null,{\"statements\":[[0,\"\\n      Drop to upload\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n      Invalid\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[23,2,[\"files\",\"length\"]]],null,{\"statements\":[[0,\"\\n    Uploading \"],[1,[23,2,[\"files\",\"length\"]],false],[0,\" files. (\"],[1,[23,2,[\"progress\"]],false],[0,\"%)\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n    \"],[7,\"h4\",true],[8],[0,\"Upload Images\"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[8],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"supported\"]]],null,{\"statements\":[[0,\"\\n        Drag and drop images onto this area to upload them or\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"file-upload\",null,[[\"name\",\"accept\",\"multiple\",\"onfileadd\"],[\"photos\",\"image/*\",true,[28,\"action\",[[23,0,[]],\"uploadImage\"],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"a\",true],[10,\"id\",\"upload-image\"],[10,\"tabindex\",\"0\"],[8],[0,\"Add an Image.\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/photo-upload/template.hbs"
    }
  });

  _exports.default = _default;
});