define("ccl-scoring-admin-ui/components/dq-table/component", ["exports", "ember-light-table"], function (_exports, _emberLightTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    table: null,
    dir: "asc",
    enableSync: true,
    columns: Ember.computed(function () {
      return [{
        label: "ID",
        valuePath: "id",
        cellComponent: "table-action-link",
        width: "40px",
        sortable: false
      }, {
        label: "Season",
        valuePath: "season.name"
      }, {
        label: "Club",
        valuePath: "club.name"
      }, {
        label: "Group",
        valuePath: "group.name"
      }, {
        label: "Gender",
        valuePath: "gender"
      }, {
        label: "Replacement",
        valuePath: "replacement"
      }];
    }),

    init() {
      this._super(...arguments);

      const columns = Ember.get(this, "columns");
      const rows = Ember.get(this, "model");
      const enableSync = Ember.get(this, "enableSync");

      const table = _emberLightTable.default.create({
        columns,
        rows,
        enableSync
      });

      Ember.set(this, "table", table);
    }

  });

  _exports.default = _default;
});