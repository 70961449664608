define("ccl-scoring-admin-ui/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xs7fkpyj",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"block-center mt-4 wd-xl\"],[8],[0,\"\\n\\n   \"],[2,\" START card\"],[0,\"\\n   \"],[7,\"div\",true],[10,\"class\",\"card card-flat\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"card-header text-center bg-light\"],[8],[0,\"\\n         \"],[7,\"a\",true],[10,\"href\",\"#\"],[8],[0,\"\\n            \"],[7,\"img\",true],[10,\"class\",\"block-center rounded img-fluid\"],[10,\"src\",\"images/CCL-swoosh.png\"],[10,\"alt\",\"Image\"],[8],[9],[0,\"\\n         \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"card-body\"],[8],[0,\"\\n\\n         \"],[7,\"p\",true],[10,\"class\",\"text-center py-2\"],[8],[0,\"SIGN IN TO CONTINUE.\"],[9],[0,\"\\n\\n\"],[4,\"bs-button\",null,[[\"type\",\"class\",\"block\",\"onClick\"],[\"primary\",\"mt-3\",true,[28,\"action\",[[23,0,[]],\"login\"],null]]],{\"statements\":[[0,\"          Login\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n      \"],[9],[0,\"\\n   \"],[9],[0,\"\\n   \"],[2,\" END card\"],[0,\"\\n\\n   \"],[7,\"div\",true],[10,\"class\",\"p-3 text-center\"],[8],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"©\"],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"2019\"],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"Club Champions League\"],[9],[0,\"\\n      \"],[7,\"br\",true],[8],[9],[0,\"\\n      \"],[7,\"span\",true],[8],[0,\"v\"],[1,[28,\"app-version\",null,[[\"versionOnly\"],[true]]],false],[9],[0,\"\\n   \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/index/template.hbs"
    }
  });

  _exports.default = _default;
});