define("ccl-scoring-admin-ui/club/coach/model", ["exports", "ember-data", "ccl-scoring-admin-ui/position/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    belongsTo,
    attr
  } = _emberData.default;

  var _default = _model.default.extend({
    'status': attr('string'),
    'meta': attr(),
    'updatedTime': attr('date'),
    'publishedTime': attr('date'),
    'updatedBy': belongsTo('user', {
      'inverse': null
    }),
    'club': belongsTo('club', {
      'inverse': null
    }),
    'group': belongsTo('group', {
      'inverse': null
    }),
    'season': belongsTo('season', {
      'inverse': null
    })
  });

  _exports.default = _default;
});