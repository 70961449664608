define("ccl-scoring-admin-ui/components/table-clubs-cell/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'homeScoreEdit': false,
    'awayScoreEdit': false,
    'row': null,
    'model': Ember.computed.alias('row.content'),
    actions: {
      toggleHomeScoreEdit() {
        Ember.set(this, 'homeScoreEdit', true);
      },

      toggleAwayScoreEdit() {
        Ember.set(this, 'awayScoreEdit', true);
      },

      revert() {
        const model = this.model;
        Ember.set(this, 'homeScoreEdit', false);
        Ember.set(this, 'awayScoreEdit', false);
        model.rollbackAttributes();
      },

      noop() {
        return false;
      }

    },
    'save': (0, _emberConcurrency.task)(function* () {
      const model = this.model;
      yield model.save();
      Ember.set(this, 'homeScoreEdit', false);
      Ember.set(this, 'awayScoreEdit', false);
    })
  });

  _exports.default = _default;
});