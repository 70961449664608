define("ccl-scoring-admin-ui/league/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    serialize(snapshot, options) {
      const json = this._super(snapshot, options);

      json.data.attributes['schedule-enabled'] = json.data.attributes['schedule-enabled'] ? 1 : 0;
      json.data.attributes['scoring-enabled'] = json.data.attributes['scoring-enabled'] ? 1 : 0;
      return json;
    }

  });

  _exports.default = _default;
});