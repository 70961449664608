define("ccl-scoring-admin-ui/components/match-filter-status/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    options: null,
    selected: Ember.computed('status', function () {
      return this.status.map(status => {
        return this.options.findBy('name', status);
      });
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'options', [{
        name: 'final',
        className: 'text-success'
      }, {
        name: 'unofficial',
        className: 'text-primary'
      }, {
        name: 'scheduled',
        className: 'text-primary'
      }, {
        name: 'rescheduled',
        className: 'text-warning'
      }, {
        name: 'trash',
        className: 'text-danger'
      }]);
    }

  });

  _exports.default = _default;
});