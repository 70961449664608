define("ccl-scoring-admin-ui/match/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pzJK3Tjh",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"page-title\",[\"Matches\"],null],false],[0,\"\\n\\n\"],[1,[22,\"nav-top\"],false],[0,\"\\n\\n\"],[1,[22,\"nav-side\"],false],[0,\"\\n\\n\"],[7,\"aside\",true],[10,\"id\",\"match-offsidebar-wormhole\"],[10,\"class\",\"offsidebar\"],[8],[9],[0,\"\\n\\n\"],[1,[22,\"liquid-outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/match/template.hbs"
    }
  });

  _exports.default = _default;
});