define("ccl-scoring-admin-ui/components/photo-upload/component", ["exports", "ember-concurrency", "ccl-scoring-admin-ui/config/environment"], function (_exports, _emberConcurrency, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'store': Ember.inject.service(),
    'session': Ember.inject.service(),
    'wpFormatResponse': Ember.inject.service(),
    'uploadPhoto': (0, _emberConcurrency.task)(function* (file) {
      const store = this.store;
      const session = this.session;
      const format = this.wpFormatResponse;
      const token = Ember.get(session, 'data.authenticated.authorizationToken.access_token');

      try {
        const resp = yield file.upload({
          'url': "".concat(_environment.default.apiHost, "/wp-json/wp/v2/media"),
          'method': 'post',
          'headers': {
            'Authorization': "Bearer ".concat(token)
          }
        });
        const attrs = format.mediaAdapter(resp.body); // pushPayload is nice since it uses the serializer
        // but it doesn't return anything unless a feature flag is set
        // ds-pushpayload-return
        //

        store.pushPayload({
          'data': attrs
        });
        const photo = store.peekRecord('media', attrs.id);
        return this.sendAction('action', photo);
      } catch (e) {
        if (401 === e.status) {
          session.invalidate();
        }
      }
    }).maxConcurrency(3).enqueue(),
    'actions': {
      uploadImage(file) {
        return this.uploadPhoto.perform(file);
      }

    }
  });

  _exports.default = _default;
});