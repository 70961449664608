define("ccl-scoring-admin-ui/components/media-gallery/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'store': Ember.inject.service(),
    'page': 1,
    'model': null,
    'fetchMedia': (0, _emberConcurrency.task)(function* () {
      let term = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      yield (0, _emberConcurrency.timeout)(250);
      const store = this.store;
      const model = this.model;
      model.clear();
      const records = yield store.query('media', {
        'search': term
      });
      model.pushObjects(records.toArray());
    }).restartable(),

    init() {
      this._super(...arguments);

      Ember.set(this, 'model', []);
    }

  });

  _exports.default = _default;
});