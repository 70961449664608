define("ccl-scoring-admin-ui/components/widget-leaderboard-result-ccl/component", ["exports", "ember-light-table", "ember-concurrency"], function (_exports, _emberLightTable, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dir: "asc",
    sort: null,
    table: null,
    rows: null,
    store: Ember.inject.service(),
    fetch: (0, _emberConcurrency.task)(function* () {
      // make sure season had loaded
      yield this.season;
      let options = {
        season: Ember.get(this, "season.id")
      };

      if (this.gender) {
        options.gender = this.gender;
      }

      if (this.mode) {
        options.mode = this.mode;
      }

      if (this.group) {
        yield this.group;
        options.group = Ember.get(this, "group.id");
      }

      this.rows.clear();
      const response = yield this.store.query("result", options);
      this.rows.pushObjects(response.toArray());
    }),

    onColumnClick(column) {
      if (column.sorted) {
        let dir = column.ascending ? "asc" : "desc";
        let sorted = this.rows.sortBy(column.valuePath);

        if ("asc" === dir) {
          sorted.reverse();
        }

        Ember.set(this, "dir", dir);
        Ember.set(this, "sort", column.valuePath);
        this.rows.clear();
        this.rows.pushObjects(sorted);
        this.toggleColumnSort();
      }
    },

    toggleColumnSort() {
      this.table.get("allColumns").filterBy("valuePath", this.sort).map(column => {
        Ember.set(column, "sorted", true);
      });
    },

    didReceiveAttrs() {
      this._super(...arguments);

      switch (this.mode) {
        case "public":
          Ember.set(this, "sort", "public");
          break;

        case "private":
          Ember.set(this, "sort", "private");
          break;

        case "championship":
          Ember.set(this, "sort", "championship");
          break;

        default:
          Ember.set(this, "sort", "public");
      }

      this.toggleColumnSort();
      this.fetch.perform();
    },

    init() {
      this._super(...arguments);

      Ember.set(this, "rows", []);

      const table = _emberLightTable.default.create({
        columns: this.columns,
        rows: this.rows,
        enableSync: true
      });

      Ember.set(this, "table", table);
    },

    columns: Ember.computed("mode", function () {
      return [{
        label: "Club",
        valuePath: "club.name"
      }, {
        label: "Championship",
        valuePath: "championshipPoints",
        align: "right",
        breakpoints: ["desktop", "jumbo"],
        hidden: "championship" !== this.mode
      }, {
        label: "C",
        valuePath: "championshipPoints",
        align: "right",
        breakpoints: ["mobile", "tablet"],
        hidden: "championship" !== this.mode
      }, {
        label: "Private Points",
        valuePath: "privatePoints",
        align: "right",
        breakpoints: ["desktop", "jumbo"],
        hidden: "public" === this.mode
      }, {
        label: "Pr",
        valuePath: "privatePoints",
        align: "right",
        breakpoints: ["mobile", "tablet"],
        hidden: "public" === this.mode
      }, {
        label: "Public Points",
        valuePath: "publicPoints",
        align: "right",
        breakpoints: ["desktop", "jumbo"]
      }, {
        label: "Pu",
        valuePath: "publicPoints",
        align: "right",
        breakpoints: ["mobile", "tablet"]
      }, {
        label: "Wins",
        valuePath: "wins",
        align: "right",
        breakpoints: ["desktop", "jumbo"]
      }, {
        label: "W",
        valuePath: "wins",
        align: "right",
        breakpoints: ["mobile", "tablet"]
      }, {
        label: "Ties",
        valuePath: "ties",
        align: "right",
        breakpoints: ["desktop", "jumbo"]
      }, {
        label: "T",
        valuePath: "ties",
        align: "right",
        breakpoints: ["mobile", "tablet"]
      }, {
        label: "Losses",
        valuePath: "losses",
        align: "right",
        breakpoints: ["desktop", "jumbo"]
      }, {
        label: "L",
        valuePath: "losses",
        align: "right",
        breakpoints: ["mobile", "tablet"]
      }];
    })
  });

  _exports.default = _default;
});