define("ccl-scoring-admin-ui/components/group-edit/component", ["exports", "ccl-scoring-admin-ui/mixins/save-model"], function (_exports, _saveModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_saveModel.default, {
    'cclGlobals': Ember.inject.service(),
    'model': null
  });

  _exports.default = _default;
});