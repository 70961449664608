define("ccl-scoring-admin-ui/components/light-table/columns/thead-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i0jq4QC6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"hasSelection\"]]],null,{\"statements\":[[0,\"\\n  \"],[1,[28,\"fa-icon\",[\"check-square\"],null],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n  \"],[1,[28,\"fa-icon\",[\"square\"],null],false],[0,\"\\n\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/light-table/columns/thead-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});