define("ccl-scoring-admin-ui/components/season-results/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vT8rKecB",
    "block": "{\"symbols\":[\"club\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"card-deck\"],[8],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card\"],[10,\"style\",\"min-width:200px;\"],[8],[0,\"\\n\\n\"],[4,\"if\",[[23,1,[\"logo\"]]],null,{\"statements\":[[0,\"\\n        \"],[7,\"img\",true],[10,\"class\",\"card-img-top\"],[10,\"alt\",\"club logo\"],[11,\"src\",[29,[\"http://ccl-dev.test/wp-content/uploads/\",[23,1,[\"logo\",\"file\"]]]]],[8],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"card-body\"],[8],[0,\"\\n\\n        \"],[7,\"h5\",true],[10,\"class\",\"card-title\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\\n        \"],[7,\"p\",true],[10,\"class\",\"card-text\"],[8],[0,\"Rank: \"],[1,[28,\"add\",[1,[23,2,[]]],null],false],[9],[0,\"\\n\\n        \"],[7,\"p\",true],[10,\"class\",\"card-text\"],[8],[0,\"Points: \"],[1,[23,1,[\"points\"]],false],[9],[0,\"\\n\\n        \"],[7,\"p\",true],[10,\"class\",\"card-text\"],[8],[0,\"Championship Points: \"],[1,[23,1,[\"ccl-points\"]],false],[9],[0,\"\\n\\n        \"],[7,\"p\",true],[10,\"class\",\"card-text\"],[8],[0,\"Wins: \"],[1,[23,1,[\"wins\"]],false],[9],[0,\"\\n\\n        \"],[7,\"p\",true],[10,\"class\",\"card-text\"],[8],[0,\"Losses: \"],[1,[23,1,[\"losses\"]],false],[9],[0,\"\\n\\n        \"],[7,\"p\",true],[10,\"class\",\"card-text\"],[8],[0,\"Ties: \"],[1,[23,1,[\"ties\"]],false],[9],[0,\"\\n\\n        \"],[7,\"p\",true],[10,\"class\",\"card-text\"],[8],[7,\"small\",true],[10,\"class\",\"text-muted\"],[8],[0,\"Total Matches: \"],[1,[23,1,[\"total\"]],false],[9],[9],[0,\"\\n\\n      \"],[9],[0,\" \"],[2,\".card-body\"],[0,\"\\n\\n    \"],[9],[0,\" \"],[2,\".card\"],[0,\"\\n\\n\"]],\"parameters\":[1,2]},null],[0,\"\\n\"],[9],[0,\" \"],[2,\".card-deck \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/season-results/template.hbs"
    }
  });

  _exports.default = _default;
});