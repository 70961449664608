define("ccl-scoring-admin-ui/components/list-sortable/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0xwsZh2J",
    "block": "{\"symbols\":[\"item\",\"group\",\"item\"],\"statements\":[[4,\"sortable-group\",null,[[\"tagName\",\"class\",\"onChange\"],[\"ol\",\"dd-list\",\"reorderItems\"]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"sortable-item\",null,[[\"tagName\",\"class\",\"model\",\"group\",\"handle\"],[\"li\",\"dd-item dd3-item\",[23,3,[]],[23,2,[]],\".dd-handle\"]],{\"statements\":[[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"dd-handle dd3-handle\"],[8],[0,\" \"],[9],[2,\".dd-handle\"],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"dd3-content\"],[8],[0,\"\\n\\n        \"],[1,[23,3,[\"name\"]],false],[0,\"\\n\\n        \"],[1,[28,\"fa-icon\",[\"times\"],[[\"fixedWidth\",\"pull\",\"click\"],[true,\"right\",[28,\"action\",[[23,0,[]],\"remove\",[23,3,[]]],null]]]],false],[0,\"        \\n\\n      \"],[9],[2,\".dd3-content\"],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"],[4,\"power-select\",null,[[\"placeholder\",\"search\",\"onchange\",\"searchField\"],[[24,[\"selectPlaceholder\"]],[28,\"perform\",[[24,[\"selectModel\"]]],null],[28,\"action\",[[23,0,[]],\"add\"],null],\"name\"]],{\"statements\":[[0,\"\\n  \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/list-sortable/template.hbs"
    }
  });

  _exports.default = _default;
});