define("ccl-scoring-admin-ui/components/match-edit/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'model': null,
    'cclGlobals': Ember.inject.service(),
    'dateFormat': 'YYYY-MM-DD',
    'timeFormat': 'HH:mm:00',
    'zoom': 16,
    'dateCenter': null,
    'rescheduledDateCenter': null,
    'editHomeScore': false,
    'editAwayScore': false,
    'editHomeClub': false,
    'editAwayClub': false,
    'editHomeGroup': false,
    'editAwayGroup': false,
    'actions': {
      editHomeScore() {
        this.toggleProperty('editHomeScore');
      },

      editAwayScore() {
        this.toggleProperty('editAwayScore');
      },

      editHomeClub() {
        this.toggleProperty('editHomeClub');
      },

      editAwayClub() {
        this.toggleProperty('editAwayClub');
      },

      editHomeGroup() {
        this.toggleProperty('editHomeGroup');
      },

      editAwayGroup() {
        this.toggleProperty('editAwayGroup');
      },

      setLocation(location) {
        const model = this.model;
        Ember.set(model, 'location', location);
      },

      updateDate(moment) {
        const model = this.model;
        const format = this.dateFormat;
        Ember.set(model, 'date', moment.format(format));
      },

      updateRescheduledDate(moment) {
        const model = this.model;
        const format = this.dateFormat;
        Ember.set(model, 'rescheduledDate', moment.format(format));
      }

    },
    'homeShirtPlaceholder': Ember.computed('model.gender', function () {
      return "male" === Ember.get(this, 'model.gender') ? this.model.clubOne.get('homeShirt') : this.model.clubOne.get('womenHomeShirt');
    }),
    'awayShirtPlaceholder': Ember.computed('model.gender', function () {
      return "male" === Ember.get(this, 'model.gender') ? this.model.clubTwo.get('awayShirt') : this.model.clubTwo.get('womenAwayShirt');
    }),
    'homeShortsPlaceholder': Ember.computed('model.gender', function () {
      return "male" === Ember.get(this, 'model.gender') ? this.model.clubOne.get('homeShorts') : this.model.clubOne.get('womenHomeShorts');
    }),
    'awayShortsPlaceholder': Ember.computed('model.gender', function () {
      return "male" === Ember.get(this, 'model.gender') ? this.model.clubTwo.get('awayShorts') : this.model.clubTwo.get('womenAwayShorts');
    }),
    'homeSocksPlaceholder': Ember.computed('model.gender', function () {
      return "male" === Ember.get(this, 'model.gender') ? this.model.clubOne.get('homeShorts') : this.model.clubOne.get('womenHomeShorts');
    }),
    'awaySocksPlaceholder': Ember.computed('model.gender', function () {
      return "male" === Ember.get(this, 'model.gender') ? this.model.clubTwo.get('awaySocks') : this.model.clubTwo.get('womenAwaySocks');
    }),
    'matchDate': Ember.computed('model.date', 'model.rescheduledDate', function () {
      const date = (0, _moment.default)(Ember.get(this, 'model.date'), 'YYYY-MM-DD');
      const resDate = (0, _moment.default)(Ember.get(this, 'model.rescheduledDate'), 'YYYY-MM-DD');
      let matchDate = null;

      if (resDate.isValid()) {
        matchDate = resDate;
      } else if (date.isValid()) {
        matchDate = date;
      } else {
        matchDate = null;
      }

      return matchDate;
    })
  });

  _exports.default = _default;
});