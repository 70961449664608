define("ccl-scoring-admin-ui/components/dq-edit/component", ["exports", "ember-light-table", "ccl-scoring-admin-ui/mixins/save-model", "ember-concurrency"], function (_exports, _emberLightTable, _saveModel, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_saveModel.default, {
    page: 1,
    meta: null,
    canLoadMore: Ember.computed.bool("meta.links.next"),
    model: null,
    cclGlobals: Ember.inject.service(),
    store: Ember.inject.service(),
    matches: null,
    searchMatches: (0, _emberConcurrency.task)(function* () {
      const matches = this.matches;
      matches.clear();
      return this.fetchMatches.perform();
    }),

    onScrolledToBottom() {
      if (this.canLoadMore && this.fetchRecords.isIdle) {
        this.incrementProperty("page");
        this.fetchRecords.perform(this.allQueryParams);
      }
    },

    fetchRecords: (0, _emberConcurrency.task)(function* () {
      const store = this.store;
      const matches = this.matches;
      const model = this.model;
      const records = yield store.query("match", {
        seasons: Ember.get(model, "season.id"),
        groups: Ember.get(model, "group.id"),
        clubs: Ember.get(model, "club.id"),
        gender: Ember.get(model, "gender"),
        page: this.page
      });
      const meta = Ember.get(records, "meta");
      matches.pushObjects(records.toArray());
      Ember.set(this, "meta", meta);
    }),

    toggleReplacement() {
      this.toggleProperty("model.replacement");
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.fetchRecords.perform();
    },

    columns: Ember.computed("model", function () {
      return [{
        sortable: false,
        cellComponent: "table-dq-checked",
        width: "30px",
        model: this.model,
        type: "thead-checkbox"
      }, {
        label: "ID",
        sortable: false,
        valuePath: "id"
      }, {
        label: "Home",
        sortable: false,
        valuePath: "clubOne.name"
      }, {
        label: "Away",
        sortable: false,
        valuePath: "clubTwo.name"
      }, {
        label: "Gender",
        valuePath: "gender",
        sortable: false
      }, {
        label: "Season",
        valuePath: "season.name",
        sortPath: "season",
        breakpoints: ["tablet", "desktop", "jumbo"]
      }, {
        label: "Group",
        valuePath: "group.name",
        sortPath: "group"
      }, {
        label: "Status",
        valuePath: "status",
        sortable: false,
        breakpoints: ["desktop", "jumbo"]
      }, {
        label: "Date",
        valuePath: "date",
        sortPath: "date"
      }];
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, "matches", []);
      Ember.set(this, "meta", {
        links: {
          next: true
        }
      });
      const columns = this.columns;
      const rows = this.matches;

      const table = _emberLightTable.default.create({
        columns,
        rows,
        enableSync: true
      });

      Ember.set(this, "table", table);
    }

  });

  _exports.default = _default;
});