define("ccl-scoring-admin-ui/season/serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    EmbeddedRecordsMixin
  } = _emberData.default;

  var _default = _emberData.default.JSONAPISerializer.extend(EmbeddedRecordsMixin, {
    'attrs': {
      'conferences': {
        'embedded': 'always'
      }
    }
  });

  _exports.default = _default;
});