define("ccl-scoring-admin-ui/authorizers/wordpress-oauth2", ["exports", "ember-simple-auth/authorizers/oauth2-bearer"], function (_exports, _oauth2Bearer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2Bearer.default.extend({
    authorize(data, block) {
      const accessToken = data.authorizationToken['access_token'];

      if (!Ember.isEmpty(accessToken)) {
        block('Authorization', "Bearer ".concat(accessToken));
      }
    }

  });

  _exports.default = _default;
});