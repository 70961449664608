define("ccl-scoring-admin-ui/components/table-actions/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oIh7JlFH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"link-to\",null,[[\"route\",\"model\"],[\"match.edit\",[24,[\"row\",\"id\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"bs-button\",null,[[\"type\",\"size\"],[[24,[\"primary\"]],\"xs\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"fa-icon\",[\"edit\"],[[\"fixedWidth\"],[true]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"media\",\"isJumbo\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"bs-button\",null,[[\"type\",\"size\",\"onClick\"],[[24,[\"primary\"]],\"xs\",[28,\"action\",[[23,0,[]],[28,\"toggle\",[\"expanded\",[24,[\"row\"]]],null]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"row\",\"expanded\"]]],null,{\"statements\":[[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"chevron-down\"],[[\"fixedWidth\"],[true]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\n      \"],[1,[28,\"fa-icon\",[\"chevron-right\"],[[\"fixedWidth\"],[true]]],false],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/table-actions/template.hbs"
    }
  });

  _exports.default = _default;
});