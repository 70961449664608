define("ccl-scoring-admin-ui/components/media-gallery/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/WfgqdGL",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"col\"],[8],[0,\"\\n\\n    \"],[7,\"input\",true],[11,\"oninput\",[28,\"perform\",[[24,[\"fetchMedia\"]]],[[\"value\"],[\"target.value\"]]]],[10,\"placeholder\",\"Search Media\"],[10,\"type\",\"text\"],[8],[9],[0,\"\\n\\n  \"],[9],[2,\".col\"],[0,\"\\n\\n\"],[9],[2,\".row\"],[0,\"\\n\\n\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"model\"]]],null,{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"col-sm-6 col-md-4\"],[8],[0,\"\\n\\n\"],[4,\"bs-button\",null,[[\"type\",\"onClick\"],[\"primary\",[28,\"action\",[[23,0,[]],[24,[\"selected\"]],[23,1,[]]],null]]],{\"statements\":[[0,\"\\n        \"],[7,\"figure\",true],[10,\"class\",\"figure\"],[8],[0,\"\\n\\n          \"],[7,\"img\",true],[11,\"src\",[23,1,[\"sourceUrl\"]]],[10,\"class\",\"figure-img img-fluid\"],[11,\"alt\",[23,1,[\"altText\"]]],[8],[9],[0,\"\\n\\n          \"],[7,\"figcaption\",true],[10,\"class\",\"figure-caption\"],[8],[1,[23,1,[\"caption\"]],false],[9],[0,\"\\n\\n        \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[2,\".col\"],[0,\"\\n\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[9],[2,\".row\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/media-gallery/template.hbs"
    }
  });

  _exports.default = _default;
});