define("ccl-scoring-admin-ui/components/flash-session-control/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['card', 'card-default'],
    session: Ember.inject.service(),
    login: (0, _emberConcurrency.task)(function* () {
      const session = this.session;
      yield session.authenticate('authenticator:torii', 'wordpress-oauth2');
      this.flash.setExpiresAt();
      this.close();
    })
  });

  _exports.default = _default;
});