define("ccl-scoring-admin-ui/ccl-globals/service", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    'searchDebounce': 350,
    'genders': null,
    'status': null,
    'matchStatus': null,
    'matchScoring': null,
    'seasonScoring': null,
    'store': Ember.inject.service(),
    'rollbar': Ember.inject.service(),
    'seasons': Ember.computed('searchSeasons.lastSuccessful', function () {
      return this.store.peekAll('season').sortBy('name').reverse();
    }),
    'groups': Ember.computed('searchGroups.lastSuccessful', function () {
      return this.store.peekAll('group').sortBy('slug');
    }),
    'clubs': Ember.computed('searchClubs.lastSuccessful', function () {
      return this.store.peekAll('club').sortBy('name');
    }),
    'locations': Ember.computed('searchLocations.lastSuccessful', function () {
      return this.store.peekAll('location').sortBy('name');
    }),
    'searchLocations': (0, _emberConcurrency.task)(function* (name) {
      try {
        yield (0, _emberConcurrency.timeout)(this.searchDebounce);
        return yield this.store.query('location', {
          'name': name
        });
      } catch (e) {
        this.rollbar.error(e.message, e);
      }
    }).restartable(),
    'searchSeasons': (0, _emberConcurrency.task)(function* (name) {
      try {
        yield (0, _emberConcurrency.timeout)(this.searchDebounce);
        return yield this.store.query('season', {
          'name': name
        });
      } catch (e) {
        this.rollbar.error(e.message, e);
      }
    }).restartable(),
    'searchClubs': (0, _emberConcurrency.task)(function* (name) {
      try {
        yield (0, _emberConcurrency.timeout)(this.searchDebounce);
        return yield this.store.query('club', {
          'name': name
        });
      } catch (e) {
        this.rollbar.error(e.message, e);
      }
    }).restartable(),
    'searchGroups': (0, _emberConcurrency.task)(function* (name) {
      try {
        yield (0, _emberConcurrency.timeout)(this.searchDebounce);
        return yield this.store.query('group', {
          'name': name
        });
      } catch (e) {
        this.rollbar.error(e.message, e);
      }
    }).restartable(),
    'setLeagues': (0, _emberConcurrency.task)(function* () {
      try {
        const leagues = yield this.store.findAll('league');
        Ember.set(this, 'leagues', leagues);
      } catch (e) {
        this.rollbar.error(e.message, e);
      }
    }),

    init() {
      this._super();

      this.setLeagues.perform();
      Ember.set(this, 'genders', ['male', 'female']);
      Ember.set(this, 'status', ['enabled', 'disabled', 'archived']);
      Ember.set(this, 'matchStatus', ['final', 'unofficial', 'scheduled', 'rescheduled', 'trash']);
      Ember.set(this, 'matchScoring', ['default', 'friendly', 'dual forfeit', 'no match']);
      Ember.set(this, 'seasonScoring', [{
        'id': 0,
        'name': 'No Scoring'
      }, {
        'id': 1,
        'name': 'CCL'
      }, {
        'id': 2,
        'name': 'Pro23'
      }, {
        'id': 3,
        'name': 'CCLII'
      }, {
        'id': 4,
        'name': 'Pro23 Combined'
      }]);
    }

  });

  _exports.default = _default;
});