define("ccl-scoring-admin-ui/user/serializer", ["exports", "ember-data", "ember-inflector"], function (_exports, _emberData, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    attrs: {
      cclLeaderboardWidgets: {
        embedded: 'always'
      }
    },

    extractMeta(store, typeClass, payload) {
      if (payload && payload.hasOwnProperty('_links')) {
        let meta = payload._links;
        delete payload._links;
        return meta;
      }
    },

    keyForAttribute(attr) {
      return Ember.String.underscore(attr);
    },

    extractRelationship(relationshipModelName, relationshipHash) {
      relationshipHash.data.type = (0, _emberInflector.singularize)(relationshipHash.data.type);
      return relationshipHash.data;
    },

    keyForRelationship(key) {
      return Ember.String.underscore(key);
    }

  });

  _exports.default = _default;
});