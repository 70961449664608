define("ccl-scoring-admin-ui/utils/get-dom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getDom;

  // completely cribbed from
  // https://github.com/ef4/ember-set-body-class/blob/master/addon/util/get-dom.js
  function getDom(context) {
    let documentService = context.lookup('service:-document');

    if (documentService) {
      return documentService;
    } else {
      throw new Error('ember-wormhole could not get DOM');
    }
  }
});