define("ccl-scoring-admin-ui/components/select-season-scoring/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'actions': {
      selected(option) {
        Ember.set(this, 'selected', option);
        Ember.get(this, 'action')(option);
      }

    },
    'model': [{
      'id': 1,
      'name': 'CCL'
    }, {
      'id': 2,
      'name': 'Pro23'
    }, {
      'id': 3,
      'name': 'CCLII'
    }, {
      'id': 4,
      'name': 'Pro23 Combined'
    }],
    'selected': null,
    'selectedOption': Ember.computed('model.[]', 'selected', function () {
      const id = Ember.get(this, 'selected');
      const model = Ember.get(this, 'model');
      return model.findBy('id', parseInt(id));
    })
  });

  _exports.default = _default;
});