define("ccl-scoring-admin-ui/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.transition(this.hasClass('table-actions'), this.toValue(true), this.use('toRight'), this.reverse('toLeft'));
    this.transition(this.hasClass('leaderboard-widget-actions'), this.toValue(true), this.use('toDown'), this.reverse('toUp')); // this.transition(
    //   this.fromRoute( 'match.index' ),
    //   this.toRoute( 'match.edit' ),
    //   this.use( 'toLeft' ),
    //   this.reverse( 'toRight' )
    // );
  }
});