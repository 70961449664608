define("ccl-scoring-admin-ui/league/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "83pwhcNK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"section-container\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"content-wrapper\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"content-heading\"],[8],[0,\"\\n\\n      \"],[7,\"div\",true],[8],[0,\"New League\"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"ml-auto\"],[8],[0,\"\\n\\n        \"],[4,\"link-to\",null,[[\"route\"],[\"league.new\"]],{\"statements\":[[0,\"New\"]],\"parameters\":[]},null],[0,\"\\n\\n      \"],[9],[2,\".ml-auto\"],[0,\"\\n\\n    \"],[9],[2,\".content-heading\"],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"card card-default\"],[8],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"card-body\"],[8],[0,\"\\n\\n        \"],[1,[28,\"league-table\",null,[[\"model\"],[[24,[\"model\"]]]]],false],[0,\"\\n\\n      \"],[9],[2,\".card-body\"],[0,\"\\n\\n    \"],[9],[2,\".card\"],[0,\"\\n\\n  \"],[9],[2,\".content-wrapper\"],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/league/index/template.hbs"
    }
  });

  _exports.default = _default;
});