define("ccl-scoring-admin-ui/leaderboard/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ixrnVSsr",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"section-container\"],[8],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"content-wrapper\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"content-heading\"],[8],[0,\"\\n\\n      \"],[7,\"div\",true],[8],[0,\"Leaderboard\"],[9],[0,\"\\n\\n    \"],[9],[2,\".content-heading\"],[0,\"\\n\\n    \"],[1,[22,\"leaderboard-widgets\"],false],[0,\"\\n\\n  \"],[9],[2,\".content-wrapper\"],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/leaderboard/index/template.hbs"
    }
  });

  _exports.default = _default;
});