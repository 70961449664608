define("ccl-scoring-admin-ui/user/adapter", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "ccl-scoring-admin-ui/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    'host': _environment.default.apiHost,
    'namespace': 'wp-json/wp/v2',
    session: Ember.inject.service(),

    authorize(xhr) {
      let {
        access_token
      } = this.get('session.data.authenticated.authorizationToken');

      if (Ember.isPresent(access_token)) {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(access_token));
      }
    }

  });

  _exports.default = _default;
});