define("ccl-scoring-admin-ui/components/match-filter-gender/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "579VqXqe",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"card border-0\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card-body bg-primary rounded-left rounded-right\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"h5 mt-0\"],[8],[0,\"Genders\"],[9],[0,\"  \\n\"],[4,\"power-select\",null,[[\"selected\",\"options\",\"allowClear\",\"onchange\",\"placeholder\"],[[24,[\"gender\"]],[24,[\"genders\"]],true,[28,\"action\",[[23,0,[]],[24,[\"change\"]]],null],\"Filter Gender\"]],{\"statements\":[[0,\"      \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"      \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/match-filter-gender/template.hbs"
    }
  });

  _exports.default = _default;
});