define("ccl-scoring-admin-ui/components/location-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O8EHHsGH",
    "block": "{\"symbols\":[\"t\",\"body\"],\"statements\":[[4,\"light-table\",[[24,[\"table\"]]],[[\"tableClassNames\",\"height\"],[\"table table-hover\",\"65vh\"]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"head\"]],\"expected `t.head` to be a contextual component but found a string. Did you mean `(component t.head)`? ('ccl-scoring-admin-ui/components/location-table/template.hbs' @ L3:C4) \"],null]],[[\"iconSortable\",\"iconAscending\",\"iconDescending\",\"fixed\"],[\"fa fa-sort\",\"fa fa-sort-asc\",\"fa fa-sort-desc\",true]]],false],[0,\"\\n\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"body\"]],\"expected `t.body` to be a contextual component but found a string. Did you mean `(component t.body)`? ('ccl-scoring-admin-ui/components/location-table/template.hbs' @ L10:C5) \"],null]],[[\"canSelect\",\"scrollBuffer\",\"onScrolledToBottom\"],[false,500,[28,\"action\",[[23,0,[]],[24,[\"onScrolledToBottom\"]]],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"isLoading\"]]],null,{\"statements\":[[0,\"\\n\"],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,2,[\"loader\"]],\"expected `body.loader` to be a contextual component but found a string. Did you mean `(component body.loader)`? ('ccl-scoring-admin-ui/components/location-table/template.hbs' @ L19:C9) \"],null]],null,{\"statements\":[[0,\"\\n        \"],[7,\"h1\",true],[8],[0,\"Loading\"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/location-table/template.hbs"
    }
  });

  _exports.default = _default;
});