define("ccl-scoring-admin-ui/media/adapter", ["exports", "ember-data", "ccl-scoring-admin-ui/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend({
    'host': _environment.default.apiHost,
    'namespace': 'wp-json/wp/v2',
    'wpFormatResponse': Ember.inject.service(),

    handleResponse(status, headers, payload, requestData) {
      const wpFormat = Ember.get(this, 'wpFormatResponse.mediaAdapter');

      switch (status) {
        default:
          payload = {
            'data': Array.isArray(payload) ? payload.map(wpFormat) : wpFormat(payload)
          };
      }

      return this._super(status, headers, payload, requestData);
    }

  });

  _exports.default = _default;
});