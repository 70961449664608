define("ccl-scoring-admin-ui/components/match-table/component", ["exports", "ember-light-table", "moment", "ember-concurrency"], function (_exports, _emberLightTable, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ["isFullscreen"],
    flashMessages: Ember.inject.service(),
    rollbar: Ember.inject.service(),
    table: null,
    quickEditOpen: false,
    bulkEditOpen: false,
    enableSync: true,
    isFullscreen: false,
    actions: {
      onColumnClick(column) {
        const controllerClick = Ember.get(this, "onColumnClick");
        const allRows = Ember.get(this, "table.rows");
        const selectedRows = Ember.get(this, "table.selectedRows");

        if ("thead-checkbox" === Ember.get(column, "type")) {
          if (selectedRows.length) {
            allRows.setEach("selected", false);
          } else {
            allRows.setEach("selected", true);
          }
        } else {
          controllerClick(column);
        }
      },

      onAfterResponsiveChange(matches) {
        if (matches.indexOf("jumbo") > -1) {
          this.get("table.expandedRows").setEach("expanded", false);
        }
      },

      toggleBulkEdit() {
        this.toggleProperty("bulkEditOpen");
      },

      saveModels() {
        const flash = Ember.get(this, "flashMessages");
        const models = Ember.get(this, "table.selectedRows");
        Ember.set(this, "bulkEditOpen", false);
        const responses = models.map(model => {
          return model.content.save();
        });
        return Ember.RSVP.all(responses).then(() => {
          return flash.success("Matches Updated");
        }).catch(e => {
          flash.danger(e.message);
        });
      },

      revertModels() {
        const flash = Ember.get(this, "flashMessages");
        const models = Ember.get(this, "table.selectedRows");
        Ember.set(this, "bulkEditOpen", false);
        const responses = models.map(model => {
          return model.content.rollback();
        });
        return Ember.RSVP.all(responses).then(() => {
          return flash.warning("Matches Rolled Back");
        }).catch(e => {
          flash.danger(e.message);
        });
      }

    },
    toggleFullscreenTable: (0, _emberConcurrency.task)(function* () {
      if (!document.fullscreenElement) {
        try {
          Ember.set(this, "isFullscreen", true);
          yield this.element.querySelector(".ember-light-table").requestFullscreen();
        } catch (e) {
          Ember.get(this, "rollbar").error(e.message, e);
        }
      } else {
        if (document.exitFullscreen) {
          try {
            Ember.set(this, "isFullscreen", false);
            document.exitFullscreen();
          } catch (e) {
            Ember.get(this, "rollbar").error(e.message, e);
          }
        }
      }
    }),
    hasSelection: Ember.computed.notEmpty("table.selectedRows"),
    noSelection: Ember.computed.empty("table.selectedRows"),
    pagination: Ember.computed("meta", "page", function () {
      const pages = [];
      if (!this.meta) return;

      for (let i = this.meta["total-pages"]; i >= 1; i--) {
        pages.push(i);
      }

      return pages.reverse();
    }),
    columns: Ember.computed(function () {
      return [{
        label: "",
        sortable: false,
        align: "center",
        subColumns: [{
          label: "",
          valuePath: "status",
          cellType: "match-status",
          breakpoints: ["jumbo"],
          cellComponent: "table-cell-match-status",
          hideable: false,
          sortable: false,
          width: "10px"
        }, {
          label: "",
          cellComponent: "table-actions",
          hideable: false,
          sortable: false,
          width: "50px"
        }, {
          label: "",
          cellComponent: "table-cell-club-logos",
          hideable: false,
          sortable: false,
          width: "50px",
          breakpoints: ["jumbo"]
        }]
      }, {
        label: "Home Club",
        sortable: false,
        align: "center",
        subColumns: [{
          label: "Name",
          valuePath: "clubOne.name",
          resizable: true,
          cellComponent: "table-cell-select-edit",
          sortable: false,
          extra: {
            optionsPath: "clubs",
            modelPath: "clubOne",
            valuePath: "name",
            searchField: "name"
          }
        }, {
          label: "Score",
          width: "100px",
          valuePath: "clubOneScore",
          sortable: false,
          cellComponent: "table-cell-text-edit",
          breakpoints: ["desktop", "jumbo"],
          extra: {
            expandedLabel: "Home Score",
            controlType: "number"
          }
        }]
      }, {
        label: "Away Club",
        sortable: false,
        align: "center",
        subColumns: [{
          label: "Name",
          valuePath: "clubTwo.name",
          resizable: true,
          cellComponent: "table-cell-select-edit",
          sortable: false,
          extra: {
            optionsPath: "clubs",
            modelPath: "clubTwo",
            valuePath: "name",
            searchField: "name"
          }
        }, {
          label: "Score",
          width: "100px",
          valuePath: "clubTwoScore",
          sortable: false,
          cellComponent: "table-cell-text-edit",
          breakpoints: ["desktop", "jumbo"],
          extra: {
            expandedLabel: "Away Score",
            controlType: "number"
          }
        }]
      }, {
        label: "Meta",
        sortable: false,
        align: "center",
        subColumns: [{
          label: "Status",
          width: "100px",
          valuePath: "status",
          resizable: true,
          cellComponent: "table-cell-select-edit",
          sortable: false,
          breakpoints: ["tablet", "desktop", "jumbo"],
          extra: {
            optionsPath: "matchStatus",
            modelPath: "status"
          }
        }, {
          label: "Gender",
          width: "75px",
          valuePath: "gender",
          resizable: true,
          cellComponent: "table-cell-select-edit",
          sortable: false,
          breakpoints: ["tablet", "desktop", "jumbo"],
          extra: {
            optionsPath: "genders",
            modelPath: "gender"
          }
        }, {
          label: "Season",
          valuePath: "season.name",
          sortPath: "season",
          resizable: true,
          cellComponent: "table-cell-select-edit",
          sortable: false,
          breakpoints: ["tablet", "desktop", "jumbo"],
          extra: {
            optionsPath: "seasons",
            modelPath: "season",
            valuePath: "name",
            searchField: "name"
          }
        }, {
          label: "Group",
          width: "100px",
          valuePath: "group.name",
          resizable: true,
          cellComponent: "table-cell-select-edit",
          sortable: false,
          breakpoints: ["tablet", "desktop", "jumbo"],
          extra: {
            optionsPath: "groups",
            modelPath: "group",
            valuePath: "name",
            searchField: "name"
          }
        }, {
          label: "Scoring",
          valuePath: "scoring",
          sortPath: "Scoring",
          resizable: true,
          cellComponent: "table-cell-select-edit",
          sortable: false,
          breakpoints: ["jumbo"],
          extra: {
            optionsPath: "matchScoring",
            modelPath: "scoring",
            searchField: "name"
          }
        }]
      }, {
        label: "Date",
        sortable: false,
        align: "center",
        subColumns: [{
          label: "Day",
          cellComponent: "table-cell-match-date",
          breakpoints: ["tablet", "desktop", "jumbo"]
        }, {
          label: "Time",
          valuePath: "time",
          breakpoints: ["jumbo"],
          cellClassNames: "text-lowercase",

          format(value) {
            return "00:00:00" === value ? "TBD" : (0, _moment.default)(value, "HH:mm:ss").format("h:mm a");
          }

        }]
      }, {
        label: "Location",
        sortable: false,
        align: "center",
        subColumns: [{
          label: "Name",
          valuePath: "location.name",
          sortPath: "location",
          resizable: true,
          cellComponent: "table-cell-select-edit",
          sortable: false,
          breakpoints: ["jumbo"],
          extra: {
            optionsPath: "locations",
            modelPath: "location",
            valuePath: "name",
            searchField: "name"
          }
        }, {
          label: "Field",
          valuePath: "field",
          sortPath: "field",
          sortable: false,
          cellComponent: "table-cell-text-edit",
          breakpoints: ["jumbo"],
          extra: {
            controlType: "text"
          }
        }]
      }];
    }),

    init() {
      this._super(...arguments);

      const columns = Ember.get(this, "columns");
      const rows = Ember.get(this, "model");
      const sort = Ember.get(this, "sort");
      const enableSync = Ember.get(this, "enableSync");

      const table = _emberLightTable.default.create({
        columns,
        rows,
        enableSync
      });

      const sortColumn = table.get("allColumns").findBy("sortPath", sort); // Setup initial sort column

      if (sortColumn) {
        sortColumn.set("sorted", true);
      }

      Ember.set(this, "table", table);
    }

  });

  _exports.default = _default;
});