define("ccl-scoring-admin-ui/components/model-actions/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'router': Ember.inject.service(),
    'flashMessages': Ember.inject.service(),
    'cclGlobals': Ember.inject.service(),
    'rollbar': Ember.inject.service(),

    revertModel() {
      this.model.rollbackAttributes();
      this.flashMessages.success('Changes Canceled');
      return false;
    },

    'saveModel': (0, _emberConcurrency.task)(function* () {
      const isNew = this.model.isNew;
      const {
        validations
      } = yield this.model.validate();

      if (Ember.get(validations, 'isValid')) {
        try {
          const result = yield this.model.save();

          if (isNew) {
            this.flashMessages.success('Created');
            this.router.transitionTo("".concat(this.model.constructor.modelName, ".edit"), result);
          } else {
            this.flashMessages.success('Updated');
          }

          return result;
        } catch (e) {
          this.rollbar.error(e.message, e);
          e.errors.forEach(msg => this.flashMessages.danger(msg.detail)); // rethrow so button changes state

          throw e;
        }
      }
    }).enqueue(),
    'showMessages': (0, _emberConcurrency.task)(function* () {
      for (var i = this.model.validations.messages.length - 1; i >= 0; i--) {
        yield (0, _emberConcurrency.timeout)(30);
        this.flashMessages.warning(this.model.validations.messages[i]);
      }
    })
  });

  _exports.default = _default;
});