define("ccl-scoring-admin-ui/match/index/controller", ["exports", "ember-parachute", "moment", "ember-concurrency"], function (_exports, _emberParachute, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const queryParams = new _emberParachute.default({
    'status': {
      'defaultValue': ['scheduled'],
      'refresh': true,
      'replace': true,

      serialize(value) {
        return value.toString();
      },

      deserialize() {
        let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        return Ember.isEmpty(value) ? this.defaultValue : value.split(',');
      }

    },
    'gender': {
      'defaultValue': '',
      'refresh': true,
      'replace': true
    },
    'startDate': {
      'as': 'start-date',
      'defaultValue': (0, _moment.default)().startOf('week'),
      'refresh': true,
      'replace': true,

      serialize(value) {
        return value.format('YYYY-MM-DD');
      },

      deserialize(value) {
        return (0, _moment.default)(value, 'YYYY-MM-DD');
      }

    },
    'endDate': {
      'as': 'end-date',
      'defaultValue': (0, _moment.default)().endOf('week'),
      'refresh': true,
      'replace': true,

      serialize(value) {
        if (value) {
          return value.format('YYYY-MM-DD');
        }
      },

      deserialize(value) {
        return (0, _moment.default)(value, 'YYYY-MM-DD');
      }

    },
    'clubs': {
      'defaultValue': [],
      'refresh': true,
      'replace': true,

      serialize(value) {
        return value.toString();
      },

      deserialize() {
        let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        return Ember.isEmpty(value) ? this.defaultValue : value.split(',');
      }

    },
    'homeClubs': {
      'as': 'home-clubs',
      'defaultValue': [],
      'refresh': true,
      'replace': true,

      serialize(value) {
        return value.toString();
      },

      deserialize() {
        let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        return value.split(',');
      }

    },
    'awayClubs': {
      'as': 'away-clubs',
      'defaultValue': [],
      'refresh': true,
      'replace': true,

      serialize(value) {
        return value.toString();
      },

      deserialize() {
        let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        return value.split(',');
      }

    },
    'seasons': {
      'defaultValue': [],
      'refresh': true,
      'replace': true,

      serialize(value) {
        return value.toString();
      },

      deserialize() {
        let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        return Ember.isEmpty(value) ? this.defaultValue : value.split(',');
      }

    },
    'groups': {
      'defaultValue': [],
      'refresh': true,
      'replace': true,

      serialize(value) {
        return value.toString();
      },

      deserialize() {
        let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        return Ember.isEmpty(value) ? this.defaultValue : value.split(',');
      }

    },
    'sort': {
      'defaultValue': 'date',
      'refresh': true,
      'replace': true
    },
    'sortDir': {
      'as': 'sort-dir',
      'defaultValue': 'asc',
      'refresh': true,
      'replace': true
    }
  });

  var _default = Ember.Controller.extend(queryParams.Mixin, {
    'store': Ember.inject.service(),
    'cclGlobals': Ember.inject.service(),
    'rollbar': Ember.inject.service(),
    'dateFormat': 'YYYY-MM-DD',
    'model': null,
    'table': null,
    'meta': null,
    'center': null,
    'page': 1,
    'perPage': 30,
    'isLoading': Ember.computed.oneWay('fetchRecords.isRunning'),
    'canLoadMore': Ember.computed.bool('meta.links.next'),

    onColumnClick(column) {
      if (column.sorted) {
        let sortDir = column.ascending ? 'asc' : 'desc';
        let sort = column.get('sortPath') || column.get('valuePath');
        this.setProperties({
          'sortDir': sortDir,
          'sort': sort,
          'canLoadMore': true,
          'page': 1
        });
      }
    },

    resetAll() {
      // reset all query params to their default values specified in the query param map
      this.resetQueryParams();
    },

    onScrolledToBottom() {
      if (this.canLoadMore && this.fetchRecords.isIdle) {
        this.incrementProperty('page');
        Ember.get(this, 'fetchRecords').perform(this.allQueryParams);
      }
    },

    setPage(page) {
      Ember.set(this, 'page', page);
    },

    setGender(gender) {
      const currentVal = Ember.get(this, 'gender');
      const updateVal = gender === currentVal ? '' : gender;
      Ember.set(this, 'page', 1);
      Ember.set(this, 'gender', updateVal);
    },

    setSeasons(seasons) {
      this.setProperties({
        page: 1,
        seasons: seasons.getEach('id')
      });
    },

    setGroups(groups) {
      this.setProperties({
        page: 1,
        groups: groups.getEach('id')
      });
    },

    setClubs(clubs) {
      this.setProperties({
        page: 1,
        clubs: clubs.getEach('id')
      });
    },

    setStatus(status) {
      this.setProperties({
        page: 1,
        status: status.getEach('name')
      });
    },

    updateDateRange(_ref) {
      let {
        start,
        end
      } = _ref;
      this.setProperties({
        'startDate': start,
        'endDate': end,
        'page': 1
      });
    },

    setup(_ref2) {
      let {
        queryParams
      } = _ref2;
      Ember.set(this, 'center', queryParams.startDate.clone());
      this.fetchRecords.perform(queryParams);
    },

    queryParamsDidChange(_ref3) {
      let {
        shouldRefresh,
        queryParams,
        changed
        /*, changes */

      } = _ref3;
      const model = Ember.get(this, 'model'); // wait for endDate

      if (changed.startDate) return; // if any query param with `refresh: true` is changed, `shouldRefresh` is `true`

      if (shouldRefresh) {
        model.clear();
      }

      Ember.get(this, 'fetchRecords').perform(queryParams);
    },

    reset(_ref4, isExiting) {
      let {
        queryParams
      } = _ref4;

      if (isExiting) {
        this.resetQueryParams();
      }
    },

    centerChange(moment) {
      Ember.set(this, 'center', moment);
    },

    'fetchRecords': (0, _emberConcurrency.task)(function* (queryParams) {
      try {
        const model = Ember.get(this, 'model');
        yield (0, _emberConcurrency.timeout)(250);
        const params = Object.assign({}, queryParams, {
          'startDate': queryParams.startDate.clone().format('YYYY-MM-DD'),
          'endDate': queryParams.endDate.clone().format('YYYY-MM-DD'),
          'page': this.page
        });
        const records = yield Ember.get(this, 'store').query('match', params);
        const meta = Ember.get(records, 'meta');
        model.pushObjects(records.toArray());
        Ember.set(this, 'meta', meta);
      } catch (e) {
        Ember.get(this, 'rollbar').error(e.message, e);
      }
    }).restartable(),
    'range': Ember.computed('startDate', 'endDate', function () {
      return {
        'start': Ember.get(this, 'startDate'),
        'end': Ember.get(this, 'endDate')
      };
    }),

    init() {
      this._super(...arguments);

      Ember.set(this, 'model', []);
      Ember.set(this, 'meta', {
        'links': {
          'next': true
        }
      });
    }

  });

  _exports.default = _default;
});