define("ccl-scoring-admin-ui/components/table-cell-match-date/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showOriginal: Ember.computed('row.matchDate', 'row.date', function () {
      const matchDate = Ember.get(this, 'row.matchDate');
      const origDate = Ember.get(this, 'row.date');
      return matchDate !== origDate;
    })
  });

  _exports.default = _default;
});