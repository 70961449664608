define("ccl-scoring-admin-ui/components/list-nested/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'store': Ember.inject.service(),
    'cclGlobals': Ember.inject.service(),
    'model': [],
    'selected': null,
    'selectedModel': null,
    'primaryModelName': null,
    'subModelTitle': null,
    'subModelProperty': null,
    'subModelName': null,
    'sortDirection': 'y',
    'placeholder': 'Select Item',
    'subModelPlaceholder': 'Select SubItem',
    'actions': {
      addItem(item) {
        const store = Ember.get(this, 'store');
        const model = Ember.get(this, 'model');
        const record = store.createRecord('conference', {
          'name': Ember.get(item, 'name'),
          'group': item
        });
        model.pushObject(record);
      },

      remove(item) {
        const model = Ember.get(this, 'model');
        model.removeObject(item);
        model.deleteRecord();
      },

      reorderItems(itemModels
      /*, draggedModel */
      ) {
        Ember.set(this, 'model', itemModels);
      }

    }
  });

  _exports.default = _default;
});