define("ccl-scoring-admin-ui/components/table-action-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'row': null,
    'model': Ember.computed.alias('row.content'),
    'editPath': Ember.computed('model', function () {
      const modelName = Ember.get(this, 'model.constructor.modelName');
      return "".concat(modelName, ".edit");
    })
  });

  _exports.default = _default;
});