define("ccl-scoring-admin-ui/components/table-season-groups/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    cclGlobals: Ember.inject.service(),

    add(group) {
      const model = this.model;
      model.groups.pushObject(group);
    },

    remove(group) {
      const model = this.model;
      model.groups.removeObject(group);
      model.scoredGroups.removeObject(group.id);
    },

    toggleScoring(group) {
      const model = this.model;
      const hasValue = model.scoredGroups.findBy('id', group.id);
      hasValue ? model.scoredGroups.removeObject(group) : model.scoredGroups.pushObject(group);
    }

  });

  _exports.default = _default;
});