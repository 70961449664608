define("ccl-scoring-admin-ui/router", ["exports", "ccl-scoring-admin-ui/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('group', function () {
      this.route('new');
      this.route('edit', {
        'path': '/:group_id/edit'
      });
    });
    this.route('club', function () {
      this.route('edit', {
        'path': '/:club_id/edit'
      });
      this.route('new');
    });
    this.route('league', function () {
      this.route('new');
      this.route('edit', {
        'path': '/:league_id/edit'
      });
    });
    this.route('location', function () {
      this.route('edit', {
        'path': '/:location_id/edit'
      });
      this.route('new');
      this.route('map');
    });
    this.route('match', function () {
      this.route('calendar');
      this.route('edit', {
        'path': '/:match_id/edit'
      });
      this.route('new');
    });
    this.route('season', function () {
      this.route('edit', {
        'path': '/:season_id/edit'
      });
      this.route('new');
    });
    this.route('users', function () {
      this.route('me');
    });
    this.route('disqualification', function () {
      this.route('edit', {
        'path': '/:dq_id/edit'
      });
      this.route('new');
    });
    this.route('leaderboard', function () {});
  });
  var _default = Router;
  _exports.default = _default;
});