define("ccl-scoring-admin-ui/group/edit/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    'authenticationRoute': 'index',
    'store': Ember.inject.service(),

    model(params) {
      const store = this.store;
      return store.find('group', params.group_id);
    }

  });

  _exports.default = _default;
});