define("ccl-scoring-admin-ui/components/widget-leaderboard-result-pro23/component", ["exports", "ember-light-table", "ember-concurrency"], function (_exports, _emberLightTable, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'dir': 'asc',
    'sort': null,
    'table': null,
    'rows': null,
    'store': Ember.inject.service(),
    'fetch': (0, _emberConcurrency.task)(function* () {
      // make sure season had loaded
      yield this.season;
      let options = {
        'season': Ember.get(this, 'season.id')
      };

      if (this.gender) {
        options.gender = this.gender;
      }

      if (this.conference) {
        options.conference = Ember.get(this, 'conference.id');
      }

      this.rows.clear();
      const response = yield this.store.query('result', options);
      this.rows.pushObjects(response.toArray());
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      this.fetch.perform();
    },

    init() {
      this._super(...arguments);

      Ember.set(this, 'rows', []);

      const table = _emberLightTable.default.create({
        columns: this.columns,
        rows: this.rows,
        enableSync: true
      });

      Ember.set(this, 'table', table);
    },

    'columns': Ember.computed('mode', function () {
      return [{
        'label': 'Club',
        'valuePath': 'club.name'
      }, {
        'label': 'Points',
        'valuePath': 'publicPoints',
        'align': 'right'
      }, {
        'label': 'Wins',
        'valuePath': 'wins',
        'align': 'right',
        'breakpoints': ['desktop', 'jumbo']
      }, {
        'label': 'W',
        'valuePath': 'wins',
        'align': 'right',
        'breakpoints': ['mobile', 'tablet']
      }, {
        'label': 'Ties',
        'valuePath': 'ties',
        'align': 'right',
        'breakpoints': ['desktop', 'jumbo']
      }, {
        'label': 'T',
        'valuePath': 'ties',
        'align': 'right',
        'breakpoints': ['mobile', 'tablet']
      }, {
        'label': 'Losses',
        'valuePath': 'losses',
        'align': 'right',
        'breakpoints': ['desktop', 'jumbo']
      }, {
        'label': 'L',
        'valuePath': 'losses',
        'align': 'right',
        'breakpoints': ['mobile', 'tablet']
      }, {
        'label': 'GF',
        'valuePath': 'gf',
        'align': 'right'
      }, {
        'label': 'GA',
        'valuePath': 'ga',
        'align': 'right'
      }, {
        'label': 'GD',
        'valuePath': 'gd',
        'align': 'right'
      }];
    })
  });

  _exports.default = _default;
});