define("ccl-scoring-admin-ui/media/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    'altText': attr('string'),
    'caption': attr('string'),
    'date': attr('date'),
    'dateGmt': attr('date'),
    'description': attr('string'),
    'guid': attr('string'),
    'link': attr('string'),
    'mediaDetails': attr(),
    'mediaType': attr('string'),
    'mimeType': attr('string'),
    'modified': attr('date'),
    'modifiedGmt': attr('date'),
    'pingStatus': attr('string'),
    'slug': attr('string'),
    'sourceUrl': attr('string'),
    'status': attr('string'),
    'title': attr('string'),
    'type': attr('string'),
    'author': belongsTo('user')
  });

  _exports.default = _default;
});