define("ccl-scoring-admin-ui/components/widget-leaderboard/component", ["exports", "ember-concurrency"], function (_exports, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    'cclGlobals': Ember.inject.service(),
    'currentUser': Ember.inject.service(),
    'user': Ember.computed.alias('currentUser.user'),
    'removeWidget': (0, _emberConcurrency.task)(function* () {
      this.user.cclLeaderboardWidgets.removeObject(this.model);
      return yield this.user.save();
    }),
    'saveWidget': (0, _emberConcurrency.task)(function* () {
      yield this.user.save();
      yield (0, _emberConcurrency.timeout)(1000);
    }),
    'isPro23': Ember.computed.equal('model.season.league.id', 3)
  });

  _exports.default = _default;
});