define("ccl-scoring-admin-ui/match/model", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'status': (0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Status'
    }),
    'gender': (0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Status'
    }),
    'date': [(0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Date'
    }), (0, _emberCpValidations.validator)('date', {
      'precision': 'day',
      'format': 'YYYY-MM-DD',
      'description': 'Date'
    })],
    'rescheduleDate': [(0, _emberCpValidations.validator)('date', {
      'precision': 'day',
      'format': 'YYYY-MM-DD',
      'description': 'Date'
    })],
    'clubOne': [(0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Home Club'
    }), (0, _emberCpValidations.validator)('belongs-to', {
      'description': 'Home Club'
    })],
    'clubTwo': [(0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Away Club'
    }), (0, _emberCpValidations.validator)('belongs-to', {
      'description': 'Away Club'
    })],
    'season': [(0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Season'
    }), (0, _emberCpValidations.validator)('belongs-to', {
      'description': 'Season'
    })],
    'group': [(0, _emberCpValidations.validator)('presence', {
      'presence': true,
      'description': 'Group'
    }), (0, _emberCpValidations.validator)('belongs-to', {
      'description': 'Group'
    })]
  });

  var _default = Model.extend(Validations, {
    'status': attr('string'),
    'scoring': attr('string', {
      defaultValue() {
        return 'default';
      }

    }),
    'gender': attr('string'),
    'date': attr('string'),
    'time': attr('string'),
    'matchDate': attr('string'),
    'rescheduledDate': attr('string'),
    'clubOneScore': attr('number', {
      'defaultValue': 0
    }),
    'clubTwoScore': attr('number', {
      'defaultValue': 0
    }),
    'updatedTime': attr('date'),
    'publishedTime': attr('date'),
    'field': attr('string'),
    'homeShirt': attr('string'),
    'homeShorts': attr('string'),
    'homeSocks': attr('string'),
    'awayShirt': attr('string'),
    'awayShorts': attr('string'),
    'awaySocks': attr('string'),
    'clubOneTeam': attr('string'),
    'clubTwoTeam': attr('string'),
    'homeRedCards': attr('number'),
    'awayRedCards': attr('number'),
    'redCardDate': attr(),
    'clubOne': belongsTo('club', {
      'inverse': null
    }),
    'clubTwo': belongsTo('club', {
      'inverse': null
    }),
    'clubOneGroup': belongsTo('group', {
      'inverse': null
    }),
    'clubTwoGroup': belongsTo('group', {
      'inverse': null
    }),
    'group': belongsTo('group', {
      'inverse': null
    }),
    'season': belongsTo('season', {
      'inverse': null
    }),
    'dqOne': belongsTo('disqualification'),
    'dqTwo': belongsTo('disqualification'),
    'location': belongsTo('location', {
      'inverse': null
    })
  });

  _exports.default = _default;
});