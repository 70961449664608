define("ccl-scoring-admin-ui/components/match-calendar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mX/djJBv",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"as-calendar\",null,[[\"title\",\"occurrences\",\"defaultTimeZoneQuery\",\"dayStartingTime\",\"dayEndingTime\",\"timeSlotDuration\"],[\"Ember Calendar\",[24,[\"occurrences\"]],\"New York|London|Dubai|Hong Kong\",\"9:00\",\"18:00\",\"00:30\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/match-calendar/template.hbs"
    }
  });

  _exports.default = _default;
});