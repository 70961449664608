define("ccl-scoring-admin-ui/torii-providers/wordpress-oauth2", ["exports", "torii/providers/oauth2-bearer", "ccl-scoring-admin-ui/config/environment"], function (_exports, _oauth2Bearer, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2Bearer.default.extend({
    'name': 'wordpress-oauth2',
    'baseUrl': _environment.default.torii.providers['wordpress-oauth2'].baseUrl,
    'responseParams': _environment.default.torii.providers['wordpress-oauth2'].responseParams
  });

  _exports.default = _default;
});