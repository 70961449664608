define("ccl-scoring-admin-ui/components/match-filter-multiple/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5R6xMbVa",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"card border-0\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"card-body bg-primary rounded-left rounded-right\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"h5 mt-0\"],[8],[1,[22,\"placeholder\"],false],[9],[0,\"        \\n\"],[4,\"power-select-multiple\",null,[[\"selected\",\"searchField\",\"placeholder\",\"onchange\",\"search\",\"loadingMessage\"],[[24,[\"selected\"]],\"name\",\"Type to search\",[28,\"action\",[[23,0,[]],[24,[\"change\"]]],null],[28,\"perform\",[[24,[\"search\"]]],null],\"loading...\"]],{\"statements\":[[0,\"            \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/match-filter-multiple/template.hbs"
    }
  });

  _exports.default = _default;
});