define("ccl-scoring-admin-ui/application/adapter", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "ccl-scoring-admin-ui/config/environment"], function (_exports, _emberData, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    'host': _environment.default.apiHost,
    'coalesceFindRequests': true,
    'namespace': 'wp-json/ccl-scoring/v1',
    'session': Ember.inject.service(),

    authorize(xhr) {
      let {
        access_token
      } = this.get('session.data.authenticated.authorizationToken');

      if (Ember.isPresent(access_token)) {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(access_token));
      }
    },

    ajaxOptions(url, type) {
      let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      options.contentType = 'application/json';

      let hash = this._super(url, type, options);

      hash.headers['Accept'] = 'application/json';
      return hash;
    },

    'headers': Ember.computed(function () {
      return {
        'content-type': 'application/json',
        'accept': 'application/json'
      };
    }),

    findMany(store, type, ids, snapshots) {
      let url = this.buildURL(type.modelName, ids, snapshots, 'findMany');
      return this.ajax(url, 'GET', {
        data: {
          id: ids.join(',')
        }
      });
    },

    query(store, type, query) {
      const newQuery = {};
      Object.keys(query).forEach(key => {
        let dasherized = key.dasherize();
        newQuery[dasherized] = query[key];
      });
      return this._super(store, type, newQuery);
    },

    handleResponse(status, headers, payload, requestData) {
      const meta = payload.meta || {};
      meta.links = payload.links;
      payload.meta = meta;

      if (200 !== status) {
        payload = {
          'errors': [{
            'source': {
              'pointer': 'data/attributes/id'
            },
            'detail': payload.message
          }]
        };
      }

      return this._super(status, headers, payload, requestData);
    }

  });

  _exports.default = _default;
});