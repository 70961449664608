define("ccl-scoring-admin-ui/match/calendar/controller", ["exports", "ember-parachute", "moment", "ember-concurrency"], function (_exports, _emberParachute, _moment, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const queryParams = new _emberParachute.default({
    'page': {
      'defaultValue': 1,
      'refresh': true,
      'replace': false
    },
    'perPage': {
      'as': 'per-page',
      'defaultValue': 30,
      'refresh': false,
      'replace': false
    },
    'status': {
      'defaultValue': [],
      'refresh': true,
      'replace': true,

      serialize(value) {
        return value.toString();
      },

      deserialize() {
        let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        return value.split(',');
      }

    },
    'gender': {
      'defaultValue': '',
      'refresh': true,
      'replace': true
    },
    'season': {
      'defaultValue': null,
      'refresh': true,
      'replace': true,

      deserialize() {
        let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        return value ? value.toString() : null;
      }

    },
    'group': {
      'defaultValue': [],
      'refresh': true,
      'replace': true,

      serialize(value) {
        return value.toString();
      },

      deserialize() {
        let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        return value.split(',');
      }

    },
    'startDate': {
      'as': 'start-date',
      'defaultValue': (0, _moment.default)().startOf('week').format('YYYY-MM-DD'),
      'refresh': true,
      'replace': true
    },
    'endDate': {
      'as': 'end-date',
      'defaultValue': (0, _moment.default)().endOf('week').format('YYYY-MM-DD'),
      'refresh': true,
      'replace': true
    },
    'include': {
      'defaultValue': ['clubs', 'groups'],
      'refresh': true,
      'replace': true,

      serialize(value) {
        return value.toString();
      },

      deserialize() {
        let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
        return value.split(',');
      }

    }
  });

  var _default = Ember.Controller.extend(queryParams.Mixin, {
    'model': [],
    'actions': {
      setGroups(groups) {
        Ember.set(this, 'group', groups.getEach('id'));
      },

      setGender(gender) {
        Ember.set(this, 'gender', gender);
      },

      setSeason(option) {
        Ember.set(this, 'season', option.get('id'));
      },

      updateDateRange(_ref) {
        let {
          start,
          end
        } = _ref;
        const format = Ember.get(this, 'dateFormat');
        const startDate = start ? start.format(format) : null;
        const endDate = end ? end.format(format) : null;
        this.setProperties({
          'startDate': startDate,
          'endDate': endDate
        });
      },

      calendarAddOccurrence() {},

      calendarUpdateOccurrence() {},

      calendarRemoveOccurrence() {}

    },
    'occurrences': Ember.computed.map('model.[]', function (match) {
      return {
        'title': Ember.get(match, 'title'),
        'startsAt': Ember.get(match, 'startsAt'),
        'endsAt': Ember.get(match, 'endsAt')
      };
    }),
    'meta': null,
    'genders': ['male', 'female'],
    'store': Ember.inject.service(),
    'isLoading': Ember.computed.oneWay('fetchRecords.isRunning'),
    'canLoadMore': true,

    queryParamsDidChange(_ref2) {
      let {
        shouldRefresh,
        queryParams
      } = _ref2;
      const model = Ember.get(this, 'model'); // if any query param with `refresh: true` is changed, `shouldRefresh` is `true`

      if (shouldRefresh) {
        model.clear();
      }

      Ember.get(this, 'fetchRecords').perform(queryParams);
    },

    setup(_ref3) {
      let {
        queryParams
      } = _ref3;
      Ember.get(this, 'fetchRecords').perform(queryParams);
    },

    reset(_ref4, isExiting) {
      let {
        queryParams
      } = _ref4;

      if (isExiting) {
        this.resetQueryParams();
      }
    },

    'fetchRecords': (0, _emberConcurrency.task)(function* (queryParams) {
      const model = Ember.get(this, 'model');
      const records = yield Ember.get(this, 'store').query('match', queryParams);
      const meta = Ember.get(records, 'meta');
      model.pushObjects(records.toArray());
      Ember.set(this, 'canLoadMore', !Ember.isEmpty(records));
      Ember.set(this, 'meta', meta);
    }).restartable()
  });

  _exports.default = _default;
});