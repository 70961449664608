define("ccl-scoring-admin-ui/components/club-edit/component", ["exports", "ccl-scoring-admin-ui/mixins/save-model", "ember-concurrency"], function (_exports, _saveModel, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_saveModel.default, {
    'model': null,
    'store': Ember.inject.service(),
    'photoGalleryOpen': false,
    'actions': {
      togglePhotoGallery() {
        this.toggleProperty('photoGalleryOpen');
      },

      addLogo(photo) {
        const model = Ember.get(this, 'model');
        Ember.set(model, 'logo', photo);
        Ember.set(this, 'photoGalleryOpen', false);
      },

      removeLogo() {
        const model = Ember.get(this, 'model');
        Ember.set(model, 'logo', null);
      }

    },
    'queryUsers': (0, _emberConcurrency.task)(function* (query) {
      const store = Ember.get(this, 'store');
      yield (0, _emberConcurrency.timeout)(250);
      return yield store.query('user', {
        'search': query
      });
    }).restartable(),
    'updateManagers': (0, _emberConcurrency.task)(function* (selectedUsers) {
      const store = Ember.get(this, 'store');
      const model = Ember.get(this, 'model');
      const managers = Ember.get(model, 'managers');
      const users = managers.getEach('user');
      const updates = []; // check to see if the manager has been removed

      managers.forEach(manager => {
        let user = Ember.get(manager, 'user');

        if (!selectedUsers.includes(user)) {
          manager.set('status', 'inActive');
          updates.push(manager.save());
        }
      }); // check to see if the manager has been added

      selectedUsers.forEach(user => {
        if (!users.includes(user)) {
          let manager = store.createRecord('club/manager', {
            'user': user,
            'status': 'active',
            'club': model
          });
          managers.pushObject(manager);
          updates.push(manager.save());
        }
      });
      yield Ember.RSVP.all(updates);
    }).enqueue(),
    'managerUsers': Ember.computed.mapBy('activeManagers', 'user'),
    'activeManagers': Ember.computed.filterBy('model.managers', 'status', 'active')
  });

  _exports.default = _default;
});