define("ccl-scoring-admin-ui/components/table-cell-match-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2+xVUeeq",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"row\",\"matchDate\"]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showOriginal\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[8],[7,\"small\",true],[8],[1,[28,\"fa-icon\",[\"arrow-down\"],[[\"fixedWidth\"],[true]]],false],[0,\" Original Date\"],[9],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[7,\"small\",true],[8],[1,[24,[\"row\",\"date\"]],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/table-cell-match-date/template.hbs"
    }
  });

  _exports.default = _default;
});