define("ccl-scoring-admin-ui/components/table-cell-match-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "czIGtJ8f",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[8],[0,\"\\n\\n  \"],[1,[28,\"bs-tooltip\",null,[[\"placement\",\"title\"],[\"right\",[24,[\"rawValue\"]]]]],false],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/table-cell-match-status/template.hbs"
    }
  });

  _exports.default = _default;
});