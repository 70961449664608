define("ccl-scoring-admin-ui/components/match-filter-status/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JtOQvmRB",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"card border-0\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"card-body bg-primary rounded-left rounded-right\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"h5 mt-0\"],[8],[0,\"Match Status\"],[9],[0,\"  \\n\"],[4,\"power-select-multiple\",null,[[\"options\",\"selected\",\"placeholder\",\"onchange\",\"search\",\"loadingMessage\"],[[24,[\"options\"]],[24,[\"selected\"]],[24,[\"placeholder\"]],[28,\"action\",[[23,0,[]],[24,[\"change\"]]],null],[28,\"perform\",[[24,[\"search\"]]],null],\"loading...\"]],{\"statements\":[[0,\"            \"],[7,\"span\",true],[11,\"class\",[23,1,[\"className\"]]],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ccl-scoring-admin-ui/components/match-filter-status/template.hbs"
    }
  });

  _exports.default = _default;
});