define("ccl-scoring-admin-ui/components/list-sortable/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dd'],
    'cclGlobals': Ember.inject.service(),
    'model': [],
    'selectComponent': false,
    'selectedModel': null,
    'selected': null,
    'actions': {
      reorderItems(itemModels
      /*, draggedModel */
      ) {
        Ember.set(this, 'model', itemModels);
      },

      add(item) {
        const model = this.model;
        model.pushObject(item);
      },

      remove(item) {
        const model = this.model;
        model.removeObject(item);
      }

    }
  });

  _exports.default = _default;
});