define("ccl-scoring-admin-ui/components/match-filter-date/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    startCenter: Ember.computed.alias('startDate'),
    endCenter: Ember.computed.alias('endDate')
  });

  _exports.default = _default;
});