define("ccl-scoring-admin-ui/conference/adapter", ["exports", "ccl-scoring-admin-ui/application/adapter", "ember-cli-uuid/mixins/adapters/uuid"], function (_exports, _adapter, _uuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adapter.default.extend(_uuid.default, {
    pathForType()
    /* type */
    {
      return 'groups';
    }

  });

  _exports.default = _default;
});